import nlp from 'compromise';
import * as turf from '@turf/turf';

class ChatBotNLP {
  constructor() {
    this.airportData = null;
    this.datacenterData = null;
    this.lastQuery = '';
    this.initAirportData();
    this.initDatacenterData();
  }

  async initDatacenterData() {
    try {
      const datacenters = await import('../data/datacenters.json');
      this.datacenterData = datacenters.features;
    } catch (error) {
      console.error('Error loading datacenters data:', error);
    }
  }

  async initAirportData() {
    try {
      const ports = await import('../data/airports.json');
      this.airportData = this.processAirportData(ports.elements);
    } catch (error) {
      console.error('Error loading airport data:', error);
    }
  }

  processAirportData(data) {
    return data
      .filter(airport => airport.type === "node" && airport.tags)
      .map(airport => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [airport.lon, airport.lat]
        },
        properties: {
          name: airport.tags.name || 'Airport',
          icao: airport.tags.icao,
          iata: airport.tags.iata
        }
      }));
  }

  findNearbyAirports(lat, lon, radius = 150) {
    if (!this.airportData) return [];

    const point = turf.point([lon, lat]);

    const nearbyAirports = this.airportData
      .map(airport => {
        const distance = turf.distance(point, airport.geometry.coordinates, {units: 'miles'});
        return {
          ...airport,
          distance: distance
        };
      })
      .filter(airport => airport.distance <= radius)
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 20);

    return nearbyAirports.map(airport => ({
      name: airport.properties.name,
      icao: airport.properties.icao,
      iata: airport.properties.iata,
      lat: airport.geometry.coordinates[1],
      lon: airport.geometry.coordinates[0],
      distance: airport.distance.toFixed(2)
    }));
  }

  findFacilities(query) {
    if (!this.datacenterData) return [];
    const lowercaseQuery = query.toLowerCase();
    return this.datacenterData.filter(facility => 
      facility.properties.FACILITY.toLowerCase().includes(lowercaseQuery) ||
      facility.properties.CITY.toLowerCase().includes(lowercaseQuery)
    );
  }

  async processInput(input, onSelectFacility = null, onSelectAirport = null) {
    if (!this.datacenterData) {
      await this.initDatacenterData();
    }
  
    const doc = nlp(input.toLowerCase());
    
    if (doc.has('clear') || doc.has('reset') || doc.has('start over')) {
      this.facilityMap = null;
      this.lastQuery = '';
      return {
        type: 'clear'
      };
    }

    const validPatterns = [
      '(facility|facilities|datacenter|datacenters) (in|at|near)',
      'airports? near facilities?',
      'airports? near facility #Value',
      '\\[.*\\]'
    ];

    const isValidQuery = validPatterns.some(pattern => doc.has(pattern)) ||
                        input.match(/\[\s*(-?\d+\.?\d*)\s*,\s*(-?\d+\.?\d*)\s*\]/);

    if (!isValidQuery) {
        return `I can only understand specific types of queries. Please use one of these formats:\n\n` +
            `<div style="border: 1px solid transparent; padding: 10px; text-align: left;"><ul>` +
            `<li>facilities in [city/location]</li>` +
            `<li>facilities near [city/location]</li>` +
            `<li>facilities at [city/location]</li>` +
            `<li>airports near facilities in [city/location]</li>` +
            `<li>airports near facility [number] (after searching for facilities)</li>` +
            `<li>[lat,lon] for coordinate search</li>` +
            `</ul></div>\n\n` +
            `You can also type "clear" to reset the conversation.`;
    }

    const lowercaseInput = input.toLowerCase();
    const words = lowercaseInput.split(' ');
  
    const combinedMatch = lowercaseInput.match(/airports?\s+near\s+facilities?\s+(?:in|near|at)\s+([a-zA-Z\s]+)$/);
    if (combinedMatch) {
        const location = combinedMatch[1].trim();
        const facilities = this.findFacilities(location);
        
        if (facilities.length === 0) {
        return `I couldn't find any facilities in ${location}. Please try another location.`;
        }
  
      this.facilityMap = new Map();
      const facilityList = facilities.map((facility, index) => {
        const facilityNumber = index + 1;
        this.facilityMap.set(facilityNumber, facility);
        return `<div onclick="window.selectFacility(${facilityNumber})" style="cursor: pointer; padding: 4px;">` +
               `${facilityNumber}. ${facility.properties.FACILITY} (${facility.properties.CITY}, ${facility.properties.COUNTRY})` +
               `</div>`;
      }).join('\n');
  
      const selectedFacility = facilities[0];
      let [lon, lat] = selectedFacility.geometry.coordinates;
      if (Math.abs(lon) > 90) {
        [lat, lon] = [lon, lat];
      }
  
      const nearbyAirports = this.findNearbyAirports(lon, lat);
  
      const airportList = nearbyAirports.map(airport => 
        `<div onclick="window.selectAirport(${airport.lon}, ${airport.lat})" style="cursor: pointer; padding: 4px;">` +
        `${airport.distance} miles | ${airport.name}` +
        `</div>`
      ).join('\n');
  
      window.selectFacility = (number) => {
        if (onSelectFacility && this.facilityMap.has(number)) {
          onSelectFacility(this.facilityMap.get(number));
        }
      };
  
      window.selectAirport = (lat, lon) => {
        if (onSelectAirport) {
          onSelectAirport([lat, lon]);
        }
      };
  
      return `I found the following facilities in ${location}:\n\n` +
             `<div style="border: 1px solid transparent; padding: 10px; text-align: left;">` +
             `${facilityList}` +
             `</div>\n\n` +
             `Here are airports within 150 miles of ${selectedFacility.properties.FACILITY}:\n\n` +
             `<div style="border: 1px solid transparent; padding: 10px; text-align: left;">` +
             `${airportList}` +
             `</div>`;
    }
  
    const airportNearMatch = lowercaseInput.match(/airports?\s+near\s+(?:facility\s+)?(\d+)/);
    if (airportNearMatch) {
      const facilityNumber = parseInt(airportNearMatch[1]);
      if (!this.facilityMap || !this.facilityMap.has(facilityNumber)) {
        return "Invalid facility number. Please search for facilities first and then use a valid facility number.";
      }
  
      const selectedFacility = this.facilityMap.get(facilityNumber);
      let [lon, lat] = selectedFacility.geometry.coordinates;
      if (Math.abs(lon) > 90) {
        [lat, lon] = [lon, lat];
      }
      
      const nearbyAirports = this.findNearbyAirports(lon, lat);
  
      if (nearbyAirports.length > 0) {
        const airportList = nearbyAirports.map(airport => 
          `<div onclick="window.selectAirport(${airport.lon}, ${airport.lat})" style="cursor: pointer; padding: 4px;">` +
          `${airport.distance} miles | ${airport.name}` +
          `</div>`
        ).join('\n');
        
        return `Here are airports within 150 miles of ${selectedFacility.properties.FACILITY}:\n\n` +
               `<div style="border: 1px solid transparent; padding: 10px; text-align: left;">` +
               `${airportList}` +
               `</div>`;
      } else {
        return `I couldn't find any airports within 150 miles of ${selectedFacility.properties.FACILITY}. This is unexpected, please check the data or coordinates.`;
      }
    }
  
    if (words.some(word => ['facilities', 'datacenters', 'facility', 'datacenter'].includes(word))) {
      const locationWords = words.filter(word => !['facilities', 'datacenters', 'facility', 'datacenter', 'named', 'near', 'in'].includes(word));
      const location = locationWords.join(' ');
      
      const facilities = this.findFacilities(location);
      
      if (facilities.length === 0) {
        return `I couldn't find any facilities named or near ${location}. Please try another location or facility name.`;
      }
  
      this.facilityMap = new Map();
      const facilityList = facilities.map((facility, index) => {
        const facilityNumber = index + 1;
        this.facilityMap.set(facilityNumber, facility);
        return `<div onclick="window.selectFacility(${facilityNumber})" style="cursor: pointer; padding: 4px;">` +
               `${facilityNumber}. ${facility.properties.FACILITY} (${facility.properties.CITY}, ${facility.properties.COUNTRY})` +
               `</div>`;
      }).join('\n');
  
      window.selectFacility = (number) => {
        if (onSelectFacility && this.facilityMap.has(number)) {
          onSelectFacility(this.facilityMap.get(number));
        }
      };
  
      window.selectAirport = (lat, lon) => {
        if (onSelectAirport) {
          onSelectAirport([lat, lon]);
        }
      };
  
      return `I found the following facilities named or near ${location}:\n\n` +
             `<div style="border: 1px solid transparent; padding: 10px; text-align: left;">` +
             `${facilityList}` +
             `</div>\n\n` +
             `You can now ask for "airports near facility [number]" to find nearby airports.`;
    }
  
    const coordsMatch = input.match(/\[\s*(-?\d+\.?\d*)\s*,\s*(-?\d+\.?\d*)\s*\]/);
    if (coordsMatch) {
      const [, coord1, coord2] = coordsMatch;
      
      const nearbyAirports = this.findNearbyAirports(parseFloat(coord1), parseFloat(coord2));
      
      if (nearbyAirports.length > 0) {
        const airportList = nearbyAirports.map(airport => 
          `<div onclick="window.selectAirport(${airport.lon}, ${airport.lat})" style="cursor: pointer; padding: 4px;">` +
          `${airport.distance} miles | ${airport.name}` +
          `</div>`
        ).join('\n');
        
        return `Here are airports within 150 miles of [${coord1}, ${coord2}]:\n\n` +
               `<div style="border: 1px solid transparent; padding: 10px; text-align: left;">` +
               `${airportList}` +
               `</div>`;
      } else {
        return `I couldn't find any airports within 150 miles of [${coord1}, ${coord2}]. This is unexpected, please check the data or coordinates.`;
      }
    }
  
    return "To search for facilities, please use queries like:\n" +
           "- 'facilities in [city name]'\n" +
           "- 'airports near facilities in [city name]'\n" +
           "- 'airports near facility [number]'\n" +
           "You can also provide coordinates in the format [lat,lon] to find nearby airports directly.";
  }
}

export default ChatBotNLP;