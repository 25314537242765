import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import SCCard from "./SCCard";
import { useState } from "react";

const NotificationsComponent = ({ isOpen, onClose, onDeploymentAlert, onDisruptionAlert, firstOpen }) => {

    const DISRUPTION_ALERT = "disruption";
    const DELIVERY_ALERT = "delivery";

    const [deploymentAlertVisible, setDeploymentAlertVisible] = useState(true);
    const [disruptionAlertVisible, setDisruptionAlertVisible] = useState(true);

    if (!isOpen) return null;

    const handleAlertClose = (alertId) => {
        switch (alertId) {
            case DISRUPTION_ALERT:
                setDisruptionAlertVisible(false);
                break;
            case DELIVERY_ALERT:
                setDeploymentAlertVisible(false);
                break;
            default:
                return;
        }
    };

    const handleDeploymentAlertActivate = () => {
        setDeploymentAlertVisible(false);
        onDeploymentAlert();
    };

    const handleDisruptionAlertActivate = () => {
        setDisruptionAlertVisible(false);
        onDisruptionAlert();
    };
    
    return (
        <Paper
            elevation={3}
            sx={{
                position: 'fixed',
                top: 75,
                right: 65,
                width: 400,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Notifications</Typography>
                <IconButton onClick={onClose} sx={{ color: 'white' }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box display={"flex"} flexDirection={"column"} padding={1} paddingTop={0}  gap={.5}>
                { disruptionAlertVisible && <SCCard
                    firstView={firstOpen}
                    title={
                        <>
                            <Box>
                                <WarningIcon sx={{color:'red'}} />
                            </Box>
                            <span>Supply Chain Disruption</span>
                        </>
                    }
                    content={"Something about the disruption "}
                    actions={
                        <>
                            <Button variant='contained' onClick={handleDisruptionAlertActivate} autoFocus>Investigate</Button>
                            <Button onClick={(e) => handleAlertClose(DISRUPTION_ALERT)}>Close</Button>
                        </>
                    }
                />}
                { deploymentAlertVisible && <SCCard
                    firstView={firstOpen}
                    title={
                        <>
                            <Box>
                                <WarningIcon sx={{color:'orange'}} />
                            </Box>
                            <span>Delivery Delay Notice</span>
                        </>
                    }
                    content={
                        <Typography variant="body1">
                            Datacenter: Quincy MWH06<br /> 
                            Delivery ID: DO000001<br />
                            Destination: New York<br />
                            Original ETA: 2024-08-14<br />
                            New ETA: 2024-08-19
                        </Typography>
                    }
                    actions={
                        <>
                            <Button variant='contained' onClick={handleDeploymentAlertActivate} autoFocus>Investigate</Button>
                            <Button onClick={(e) => handleAlertClose(DELIVERY_ALERT)}>
                                Close
                            </Button>
                        </>
                    }
                />}
                { !deploymentAlertVisible && !disruptionAlertVisible && "No Active Notifications" }
            </Box>
        </Paper>
    );
}

export default NotificationsComponent;