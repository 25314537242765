import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ShippingList = ({ shipments }) => {
    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'ontime': return '#4caf50';
            case 'delayed': return '#ff9800';
            case 'arrived': return '#2196f3';
            default: return '#ffffff';
        }
    };

    return (
        <Box sx={{ height: '100%', overflow: 'auto' }}>
            {shipments.map((shipment) => (
                <Paper key={shipment.id} sx={{ mb: 2, backgroundColor: '#333333' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Shipment ID</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Order ID</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Order Date</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Shipment Date</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>ETA</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Supplier</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Source</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Destination</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>{shipment.shipmentId}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{shipment.orderId}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{shipment.orderDate}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{shipment.shipmentDate}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{shipment.eta}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{shipment.supplierId}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{`${shipment.source.type} ${shipment.source.id}`}</TableCell>
                                    <TableCell sx={{ color: 'white' }}>{`${shipment.target.type} ${shipment.target.id}`}</TableCell>
                                    <TableCell>
                                        <Box sx={{ 
                                            backgroundColor: getStatusColor(shipment.status), 
                                            color: '#000000', 
                                            padding: '2px 8px', 
                                            borderRadius: '4px',
                                            display: 'inline-block'
                                        }}>
                                            {shipment.status}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="h6" sx={{ color: 'white', mt: 2, mb: 1, px: 2 }}>Packing List</Typography>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>SKU</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Item</TableCell>
                                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Quantity</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shipment.packingList.map((item) => (
                                    <TableRow key={item.sku}>
                                        <TableCell sx={{ color: 'white' }}>{item.sku}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>{item.item}</TableCell>
                                        <TableCell sx={{ color: 'white' }}>{item.quantity}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ))}
        </Box>
    );
};

export default ShippingList;