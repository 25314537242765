import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [userPreferences, setUserPreferences] = useState({
    defaultSettings: {
      showFullMap: false,
      showSeaports: false,
      showAirports: false,
      mapDefaultStyle: 'night',
    },
    facilitiesSettings: {
      defaultView: 'satellite',
      datacenterPreLoad: [],
      selectedDatacenters: []
    }
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        loadUserPreferences(currentUser.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUserPreferences = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const data = userSnap.data();
        const newPreferences = {
          defaultSettings: {
            showFullMap: data.defaultSettings?.showFullMap ?? false,
            showSeaports: data.defaultSettings?.showSeaports ?? false,
            showAirports: data.defaultSettings?.showAirports ?? false,
            mapDefaultStyle: data.defaultSettings?.mapDefaultStyle ?? 'night',
          },
          facilitiesSettings: {
            defaultView: data.facilitiesSettings?.defaultView ?? 'satellite',
            datacenterPreLoad: Array.isArray(data.facilitiesSettings?.datacenterPreLoad) 
              ? data.facilitiesSettings.datacenterPreLoad 
              : [],
            selectedDatacenters: Array.isArray(data.facilitiesSettings?.selectedDatacenters)
              ? data.facilitiesSettings.selectedDatacenters
              : []
          }
        };

        setUserPreferences(newPreferences);

        if (!data.defaultSettings?.mapDefaultStyle || !data.facilitiesSettings?.defaultView) {
          await setDoc(userRef, newPreferences, { merge: true });
        }
      } else {
        const defaultPreferences = {
          defaultSettings: {
            showFullMap: false,
            showSeaports: false,
            showAirports: false,
            mapDefaultStyle: 'night',
          },
          facilitiesSettings: {
            defaultView: 'satellite',
            datacenterPreLoad: [],
            selectedDatacenters: []
          }
        };
        await setDoc(userRef, defaultPreferences);
        setUserPreferences(defaultPreferences);
      }
    } catch (error) {
      console.error('Error loading user preferences:', error);
    }
  };

  const updateUserPreferences = async (newPreferences) => {
    setUserPreferences(prevPreferences => ({
      ...prevPreferences,
      ...newPreferences
    }));

    if (user) {
      try {
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, { ...userPreferences, ...newPreferences }, { merge: true });
      } catch (error) {
        console.error('Error updating user preferences:', error);
      }
    }
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      userPreferences, 
      setUserPreferences: updateUserPreferences,
      facilities,
      setFacilities
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);