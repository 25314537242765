import { Card, CardActions, CardContent, CardHeader} from "@mui/material";

const SCCard = ({ firstView, title, content, actions }) => {
    return (
        <Card 
            variant={firstView ? "outlined" : "elevation"}
            sx={{backgroundColor: firstView ?'#333':'#222', borderRadius: .4, borderWidth: firstView ? 3 : 0}} 
            elevation={4}>
            <CardHeader 
                title={title}
            />
            <CardContent align="left">
                {content}
            </CardContent>
            <CardActions align="right">
                {actions}
            </CardActions>
        </Card>
    );
}

export default SCCard;