import React from 'react';
import { WMSTileLayer } from 'react-leaflet';

const OSMLanduseData = ({ year }) => {
  const wmsUrl = 'https://maps.heigit.org/osmlanduse/service';
  const layerName = year === 2020 ? 'osmlanduse:osm_lulc_2020_gap_filled' : 'osmlanduse:osm_lulc_gap_filled';

  return (
    <WMSTileLayer
      url={wmsUrl}
      layers={layerName}
      format="image/png"
      transparent={true}
      attribution="© OpenStreetMap contributors, OSM Landuse Landcover"
    />
  );
};

export default OSMLanduseData;