import React from 'react';
import { Box, Typography, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#2a2a2a',
  color: 'white',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '150px',
  width: 'calc(26% - 16px)',
  margin: theme.spacing(1),
}));

const KPITitle = styled(Typography)({
  color: '#a0a0a0',
  fontSize: '1rem',
  fontWeight: 'normal',
});

const KPIValue = styled(Typography)({
  fontSize: '3rem',
  fontWeight: 'bold',
});

const KPITarget = styled(Typography)({
  color: '#a0a0a0',
  fontSize: '0.9rem',
});

const GlobalKPIWindow = () => {
  const kpiData = [
    { title: '% of Aging Inventory in Hubs', target: '<15%', actual: '23%', status: 'red' },
    { title: 'On Time Delivery', target: '>95%', actual: '97%', status: 'green' },
    { title: 'Inventory Accuracy', target: '>99%', actual: '98.5%', status: 'yellow' },
    { title: 'Supplier Quality', target: '<0.5%', actual: '0.3%', status: 'green' },
    { title: 'Inventory Turnover', target: '>12', actual: '10', status: 'yellow' },
    { title: 'Order Fill Rate', target: '>98%', actual: '96%', status: 'yellow' }
  ];

  const additionalData = [
    { title: 'Datacenters', subtitle: 'Operational', value: '328', color: '#FF9800' },
    { title: 'Datacenters', subtitle: 'In Deployment', value: '32', color: '#00BCD4' },
    { title: 'Datacenters', subtitle: 'Under Construction', value: '150', color: '#9C27B0' },
    { title: 'Warehouses', subtitle: 'Operational', value: '61', color: '#8BC34A' },
    { title: 'In Transit', subtitle: 'Ships', value: '74', color: '#4CAF50' },
    { title: 'In Transit', subtitle: 'Planes', value: '43', color: '#2196F3' },
    { title: 'In Transit', subtitle: 'Trains', value: '54', color: '#F44336' }
  ];

  const renderTrendIcon = (status) => {
    if (status === 'green') {
      return <ArrowUpwardIcon sx={{ color: 'green', fontSize: '1.5rem' }} />;
    } else if (status === 'red') {
      return <ArrowDownwardIcon sx={{ color: 'red', fontSize: '1.5rem' }} />;
    }
    return null;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: '#1e1e1e' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', mb: 2 }}>
        {kpiData.slice(0, 3).map((kpi, index) => (
          <StyledCard key={index}>
            <KPITitle>{kpi.title}</KPITitle>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <KPIValue>{kpi.actual}</KPIValue>
              {renderTrendIcon(kpi.status)}
            </Box>
            <KPITarget>{kpi.target}</KPITarget>
          </StyledCard>
        ))}
      </Box>
      
      <Typography variant="h6" sx={{ color: 'white', ml: 1, mb: 0.6 }}>Other Metrics</Typography>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
        {additionalData.slice(0, 6).map((item, index) => (
          <StyledCard key={index}>
            <KPITitle>{item.title}</KPITitle>
            <Typography variant="body2" sx={{ color: '#a0a0a0', fontSize: '0.9rem' }}>
              {item.subtitle}
            </Typography>
            <KPIValue>{item.value}</KPIValue>
          </StyledCard>
        ))}
      </Box>
    </Box>
  );
};

export default GlobalKPIWindow;