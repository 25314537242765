import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const OSMLanduseChart = ({ data }) => {
  const COLORS = ['#00FF00', '#FFFF00', '#FF0000', 'rgba(177, 7, 233, 1.0)', 'rgba(231, 175, 249, 1.0)', 'rgba(134, 198, 237, 1.0)'];

  const pieData = Object.entries(data).map(([name, value]) => ({
    name,
    value: value.percentage,
  }));

  return (
    <ResponsiveContainer width="100%" height={275}>
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OSMLanduseChart;