import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { AzureMapsProvider, AzureMap, AzureMapHtmlMarker } from 'react-azure-maps';
import { AuthenticationType } from 'azure-maps-control';
import CloseIcon from '@mui/icons-material/Close';
import 'azure-maps-control/dist/atlas.min.css';

const DCInfoWindow = ({ facilityData, onClose }) => {
    const mapRef = useRef(null);
    const [isMapReady, setIsMapReady] = useState(false);
    const subscriptionApiKey = process.env.REACT_APP_AZURE_API_KEY;

    const geolocation = useMemo(() => [
        facilityData.FacilityCoordinates.long,
        facilityData.FacilityCoordinates.lat
    ], [facilityData.FacilityCoordinates.long, facilityData.FacilityCoordinates.lat]);

    useEffect(() => {
        if (mapRef.current && isMapReady) {
            mapRef.current.setCamera({
                center: geolocation,
                zoom: 17
            });
        }
    }, [geolocation, isMapReady]);

    const onMapLoad = (mapInstance) => {
        setIsMapReady(true);
        mapRef.current = mapInstance.map;
    };

    const mapOptions = useMemo(() => ({
        center: geolocation,
        zoom: 17,
        authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: subscriptionApiKey
        },
        style: 'satellite_road_labels',
        renderWorldCopies: false
    }), [geolocation, subscriptionApiKey]);

    return (
        <Box sx={{
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#333333',
            borderRadius: '8px',
            overflow: 'hidden',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        }}>
            <Box sx={{ width: '100%', height: '200px', padding: 0, margin: 0, position: 'relative' }}>
                <AzureMapsProvider>
                    <AzureMap
                        ref={mapRef}
                        options={mapOptions}
                        style={{ width: '100%', height: '100%' }}
                        events={{
                            ready: onMapLoad,
                        }}
                    >
                        <AzureMapHtmlMarker options={{ position: geolocation }} />
                    </AzureMap>
                </AzureMapsProvider>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: '#333333',
                        color: 'white',
                        borderRadius: '50%',
                        padding: 1,
                        '&:hover': {
                            backgroundColor: '#444444',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4" sx={{ fontSize: '24px', mb: 1, color: 'white', fontWeight: 'bold' }}>
                    {facilityData.FacilityName}
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', lineHeight: 1.2, fontSize: '14px' }}>
                    {`${facilityData.FacilityAddress.street}, ${facilityData.FacilityAddress.city}, ${facilityData.FacilityAddress.state} ${facilityData.FacilityAddress.postalCode}`}
                </Typography>
                <Typography variant="body1" sx={{ color: 'white', lineHeight: 1.2, fontSize: '14px' }}>
                    {`${facilityData.FacilityAddress.country}, ${facilityData.FacilityAddress.region}`}
                </Typography>
            </Box>
        </Box>
    );
};

export default DCInfoWindow;