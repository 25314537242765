import React, { useState, useCallback } from 'react';
import { MapContainer, TileLayer, WMSTileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import OSMMapUpdater from './OSMMapUpdater';
import OSMMapControls from './OSMMapControls';

const OSMLanduseMap = ({ center, zoom, children, overlays, onOverlayChange }) => {
  const [basemap, setBasemap] = useState('osmCarto');

  const mapStyle = {
    height: '100%',
    width: '100%',
  };

  const handleBasemapChange = useCallback((newBasemap) => {
    setBasemap(newBasemap);
  }, []);

  return (
      <MapContainer center={center} zoom={zoom} style={mapStyle} zoomControl={false}>
        <OSMMapUpdater center={center} zoom={zoom} />
        
        {basemap === 'osmCarto' && (
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        )}
        {basemap === 'esriWorldImagery' && (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
          />
        )}
        
        {overlays.current && (
          <WMSTileLayer
            url="https://maps.heigit.org/osmlanduse/service"
            layers="osmlanduse:osm_lulc"
            version="1.3.0"
            format="image/png"
            transparent={true}
            attribution="© OpenStreetMap contributors, OSM Landuse Landcover"
          />
        )}
        {overlays.gapFilled2020 && (
          <WMSTileLayer
            url="https://maps.heigit.org/osmlanduse/service"
            layers="osmlanduse:osm_lulc_combined_osm4eo"
            version="1.1.0"
            format="image/png"
            transparent={true}
            attribution="© OpenStreetMap contributors, OSM Landuse Landcover"
          />
        )}
        
        <div style={{
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 1000,
      }}>
        <OSMMapControls 
          onOverlayChange={onOverlayChange} 
          onBasemapChange={handleBasemapChange}
          overlays={overlays}
          basemap={basemap}
        />
      </div>
      
      {children}
    </MapContainer>
  );
};

export default OSMLanduseMap;