import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCzI5fHF6q7kSPb8wI8gcU2x4h8dkHm0JA",
  authDomain: "scm-msn.firebaseapp.com",
  projectId: "scm-msn",
  storageBucket: "scm-msn.appspot.com",
  messagingSenderId: "169540475472",
  appId: "1:169540475472:web:c04cd11fabf14cf3190f55",
  measurementId: "G-MJW2QWSKPE"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore();

export const storage = getStorage(app);
export {db, auth};
export default app;
