import React, { useEffect, useState, useCallback, useRef} from 'react';
import * as atlas from 'azure-maps-control';

const MapShippingRoutes = React.memo(({ map, mapStyle, zoomTo, zoomScale = 2, setShippingLayers }) => {
    const [deploymentsData, setDeploymentsData] = useState(null);
    const originalLayersRef = useRef([]);
    const alternativeLayersRef = useRef([]);
    const originalMarkersRef = useRef([]);
    const alternativeMarkersRef = useRef([]);
    const pinMarkersRef = useRef([]);
    const layersCreatedRef = useRef(false);
    const imagesLoadedPromise = useRef(null);
    const markerIdCounterRef = useRef(0);

    const generateUniqueMarkerId = (prefix = 'marker') => {
        markerIdCounterRef.current += 1;
        return `${prefix}_${markerIdCounterRef.current}`;
      };

    const loadImages = () => {
        if (!imagesLoadedPromise.current) {
          const imagesToLoad = [
            { name: 'customRedCircle', url: '../images/icon_circle_red.png' },
            { name: 'customYellowCircle', url: '../images/icon_circle_yellow.png' },
            { name: 'customBlueCircle', url: '../images/icon_circle_blue.png' },
            { name: 'customGreenCircle', url: '../images/icon_circle_green.png' },
            { name: 'customArrow', url: '../images/icon_arrow_blue.png' }
          ];
    
          imagesLoadedPromise.current = Promise.all(
            imagesToLoad.map(img => 
              map.imageSprite.hasImage(img.name) 
                ? Promise.resolve() 
                : map.imageSprite.add(img.name, img.url)
            )
          ).then(() => {
            console.log('map images for routes loaded!');
          }).catch((error) => {
            console.error('Error adding images:', error);
          });
        }
        return imagesLoadedPromise.current;
      };


    useEffect(() => {
        const loadData = async () => {
            try {
                const [shippingRoutes] = await Promise.all([
                    import('../data/deployments.json')
                ]);
                setDeploymentsData(shippingRoutes.default);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        if (!map || !mapStyle || !zoomTo) return;
        

        map.setStyle({ style: mapStyle });

        if (map && zoomTo) {
            map.setCamera({
                center: zoomTo,
                zoom: zoomScale,
                type: 'ease',
                duration: 100,
                easingFunction: 'easeOutCubic'
            });
        }
    }, [map, mapStyle, zoomTo, zoomScale]);

    const showDeploymentTransit = useCallback((data) => {
        if (!map ||  layersCreatedRef.current) return;

        loadImages().then(() => {
            const origSource = new atlas.source.DataSource();
            const origDashedSource = new atlas.source.DataSource();
            const altSource = new atlas.source.DataSource();
            const markersSource = new atlas.source.DataSource();
            const altMarkersSource = new atlas.source.DataSource();

            const origLayer = new atlas.layer.LineLayer(origSource, 'origLayer', {
                color: 'blue',
                width: 4
            });

            const origDashedLayer = new atlas.layer.LineLayer(origDashedSource, 'origDashedLayer', {
                strokeColor: 'grey',
                width: 4,
                strokeDashArray: [2, 4]
            });
        
            const markerCBLayer = new atlas.layer.SymbolLayer(markersSource, 'markerCBLayer', {
                iconOptions: {
                    image: 'customBlueCircle',
                    size: 0.1,
                    anchor: 'center',
                    allowOverlap: true  
                },
                filter: ['==', ['get', 'type'], 'start']
            });
       
            const markerCRLayer = new atlas.layer.SymbolLayer(markersSource, 'markerCRLayer', {
                iconOptions: {
                    image: 'customRedCircle',
                    size: 0.1,
                    anchor: 'center',
                    allowOverlap: true
                },
                filter: ['==', ['get', 'type'], 'end']
            });
        
            const markerCYLayer = new atlas.layer.SymbolLayer(markersSource, 'markerCYLayer', {
                iconOptions: {
                    image: 'customYellowCircle',
                    size: 0.1,
                    anchor: 'center',
                    allowOverlap: true
                },
                filter: ['==', ['get', 'type'], 'alt']
            });
        
            const origArrowLayer = new atlas.layer.SymbolLayer(markersSource, 'origArrowLayer', {
                iconOptions: {
                    image: 'customArrow',
                    allowOverlap: true,
                    anchor: 'center',
                    size: 0.15,
                    rotation: ['get', 'rotation']
                },
                filter: ['==', ['get', 'type'], 'arrow']
            });
        
            const altLayer = new atlas.layer.LineLayer(altSource, 'altLayer', {
                color: 'green',
                width: 4,
                strokeDashArray: [2, 4],
                allowOverlap: true
            });
        
            const altMarkerCYLayer = new atlas.layer.SymbolLayer(altMarkersSource, 'altMarkerCYLayer', {
                iconOptions: {
                    image: 'customYellowCircle',
                    size: 0.1,
                    anchor: 'center',
                    allowOverlap: true
                },
                filter: ['==', ['get', 'type'], 'alt']
            });
       
            const altArrowLayer = new atlas.layer.SymbolLayer(altMarkersSource, 'altArrowLayer', {
                iconOptions: {
                    image: 'customArrow',
                    allowOverlap: true,
                    anchor: 'center',
                    size: 0.15,
                    rotation: ['get', 'rotation']
                },
                filter: ['==', ['get', 'type'], 'arrow']
            });
        
            map.sources.add(origSource);
            map.sources.add(origDashedSource);
            map.sources.add(altSource);
            map.sources.add(markersSource);
            map.sources.add(altMarkersSource);

            map.layers.add(origLayer);
            map.layers.add(origDashedLayer);
            map.layers.add(altLayer);
            map.layers.add(markerCBLayer);
            map.layers.add(markerCRLayer);
            map.layers.add(markerCYLayer);
            map.layers.add(altMarkerCYLayer);
            map.layers.add(origArrowLayer);
            map.layers.add(altArrowLayer);
                
            originalLayersRef.current = [origLayer, origDashedLayer, markerCBLayer, markerCRLayer, markerCYLayer, origArrowLayer];
            originalMarkersRef.current = [];

            alternativeLayersRef.current = [altLayer, altMarkerCYLayer, altArrowLayer];
            alternativeMarkersRef.current = [];

            var altRouteAvailable = false;
            var destHubAvailable = false;
            var altEndCoords = [];
            var destHubCoords = [];
            var destFinalCoords = [];
    
            data.datacenter.deployments.forEach((deployment) => {
                deployment.shipments.forEach((shipment) => {
                    const startCoords = [shipment.source.geolocation.long, shipment.source.geolocation.lat];
                    const endCoords = [shipment.target.geolocation.long, shipment.target.geolocation.lat];

                    markersSource.add(new atlas.data.Feature(new atlas.data.Point(startCoords), { type: 'start' }));
                    markersSource.add(new atlas.data.Feature(new atlas.data.Point(endCoords), { type: 'end' }));

                    const path = [startCoords, pointAtPercentage(startCoords, endCoords, 50), endCoords];

                    const firstPartLine = new atlas.data.LineString([path[0], path[1]]);
                    origSource.add(firstPartLine);

                    const secondPartLine = new atlas.data.LineString([path[1], path[2]]);
                    origDashedSource.add(secondPartLine);
                    
                    const bearing = atlas.math.getHeading(path[1], endCoords);
                    const midpoint = pointAtPercentage(startCoords, endCoords, 50);
                    markersSource.add(new atlas.data.Feature(new atlas.data.Point(midpoint), { type: 'arrow', rotation: bearing}));
                
                    let markerId = generateUniqueMarkerId('iconMarker');
                    
                    let imgType = '<img id="' + markerId +'" src="../images/icon_boat_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    if (shipment.target.type === 'airport') {
                        imgType = '<img id="' + markerId +'" src="../images/icon_plane_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    }

                    if (shipment.target.type === 'train') {
                        imgType = '<img id="' + markerId +'" src="../images/icon_train_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    }

                    const iconMarker = new atlas.HtmlMarker({
                        position: path[1],
                        htmlContent: imgType,
                        metadata: { customId: markerId }
                    });
                    

                    map.markers.add(iconMarker);
                    originalMarkersRef.current.push(iconMarker);

                    altRouteAvailable = false;
                    destHubAvailable = false;

                    if (shipment.target_alt !== undefined) {
                        altRouteAvailable = true;
                        altEndCoords = [shipment.target_alt.geolocation.long, shipment.target_alt.geolocation.lat];

                        altMarkersSource.add(new atlas.data.Feature(new atlas.data.Point(altEndCoords), { type: 'alt' }));

                        const altLine = new atlas.data.LineString([startCoords, altEndCoords]);
                        altSource.add(altLine);

                        const bearing = atlas.math.getHeading(startCoords, altEndCoords);
                        const midpoint = pointAtPercentage(startCoords, altEndCoords, 50);
                        altMarkersSource.add(new atlas.data.Feature(new atlas.data.Point(midpoint), { type: 'arrow', rotation: bearing}));
                    
                        let markerId = generateUniqueMarkerId('iconMarker');
                    
                        let imgType = '<img id="' + markerId +'" src="../images/icon_boat_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                        if (shipment.target.type === 'airport') {
                            imgType = '<img id="' + markerId +'" src="../images/icon_plane_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                        }

                        if (shipment.target.type === 'train') {
                            imgType = '<img id="' + markerId +'" src="../images/icon_train_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                        }

                        const iconMarker = new atlas.HtmlMarker({
                            position: midpoint,
                            htmlContent: imgType,
                            customId: markerId
                        });

                        map.markers.add(iconMarker);
                        alternativeMarkersRef.current.push(iconMarker);
                    }

                    if (shipment.destination_hub !== undefined) {
                        destHubAvailable = true;
                        destHubCoords = [shipment.destination_hub.geolocation.long, shipment.destination_hub.geolocation.lat];

                        
                        const markerId = generateUniqueMarkerId('hubMarker');
                        const htmlContent = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 36.5" id="' + markerId + '" width="24.5" height="36.5" display="block"><path d="M12.25.25a12.2543,12.2543,0,0,0-12,12.4937c0,6.4436,6.4879,12.1093,11.059,22.5641.5493,1.2563,1.3327,1.2563,1.882,0C17.7621,24.8529,24.25,19.1857,24.25,12.7437A12.2543,12.2543,0,0,0,12.25.25Z"   fill="{color}" stroke="{secondaryColor}" stroke-width="0.5"/><text style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; fill: white;" text-anchor="middle" x="12" y="18">{text}</text></svg>'
                        const hubMarker = new atlas.HtmlMarker({
                            position: destHubCoords,
                            color: 'blue',
                            text: "HB",
                            htmlContent: htmlContent
                        });
                        
                        map.markers.add(hubMarker);
                        pinMarkersRef.current.push(hubMarker);

                        var hubLine = new atlas.data.LineString([endCoords, destHubCoords]);
                        origDashedSource.add(hubLine);

                        if (altRouteAvailable) {
                            hubLine = new atlas.data.LineString([altEndCoords, destHubCoords]);
                            altSource.add(hubLine);
                        }
                    }
                    
                    if (shipment.destination_final !== undefined) {
                        destFinalCoords = [shipment.destination_final.geolocation.long, shipment.destination_final.geolocation.lat];
                        
                        const markerId = generateUniqueMarkerId('dcMarker');
                        const htmlContent = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.5 36.5" id="' + markerId + '" width="24.5" height="36.5" display="block"><path d="M12.25.25a12.2543,12.2543,0,0,0-12,12.4937c0,6.4436,6.4879,12.1093,11.059,22.5641.5493,1.2563,1.3327,1.2563,1.882,0C17.7621,24.8529,24.25,19.1857,24.25,12.7437A12.2543,12.2543,0,0,0,12.25.25Z"   fill="{color}" stroke="{secondaryColor}" stroke-width="0.5"/><text style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; fill: white;" text-anchor="middle" x="12" y="18">{text}</text></svg>'
                        const dcMarker = new atlas.HtmlMarker({
                            position: destFinalCoords,
                            color: 'blue',
                            text: "DC",
                            htmlContent: htmlContent
                        });
                        
                        map.markers.add(dcMarker);
                        pinMarkersRef.current.push(dcMarker);

                        let dcLine = new atlas.data.LineString([endCoords, destFinalCoords]);
                        if (destHubAvailable) {
                            dcLine = new atlas.data.LineString([destHubCoords, destFinalCoords]);
                        } 
                        origDashedSource.add(dcLine);

                        if (altRouteAvailable && !destHubAvailable) {
                            dcLine = new atlas.data.LineString([altEndCoords, destFinalCoords]);
                            altSource.add(dcLine);
                        }
                    }
                });
            });

            setShippingLayers({
                originalLayers: originalLayersRef.current,
                originalMarkers: originalMarkersRef.current,
                alternativeLayers: alternativeLayersRef.current,
                alternativeMarkers: alternativeMarkersRef.current,
                pinMarkers: pinMarkersRef.current
            });
        
            layersCreatedRef.current = true;
        });
    }, [map, setShippingLayers]);

    const pointAtPercentage = useCallback((startCoords, endCoords, percentage) => {
        percentage = Math.max(0, Math.min(100, percentage)) / 100;
        
        const lon = startCoords[0] + (endCoords[0] - startCoords[0]) * percentage;
        const lat = startCoords[1] + (endCoords[1] - startCoords[1]) * percentage;
        
        return [lon, lat];
    }, []);
    
    useEffect(() => {
        if (map && deploymentsData) {
          showDeploymentTransit(deploymentsData);
        }
    }, [map, deploymentsData, showDeploymentTransit]);

    return null;
});

export default MapShippingRoutes;