import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import TrainIcon from '@mui/icons-material/Train';
import HubIcon from '@mui/icons-material/Hub';

const TransitPanel = ({ transitStates, onTransitChange, onClose }) => {
  const transits = [
    { name: 'datacenters', label: 'Datacenters', icon: StorageIcon },
    { name: 'seaports', label: 'Seaports', icon: DirectionsBoatIcon },
    { name: 'airports', label: 'Airports', icon: LocalAirportIcon },
    { name: 'ground', label: 'Train/Trucks', icon: TrainIcon },
    { name: 'hubs', label: 'Hubs', icon: HubIcon },
  ];

  const disabledTransits = ['ground', 'hubs'];

  const handleTransitToggle = (transitName) => {
    const updatedTransits = { ...transitStates };
    updatedTransits[transitName] = !updatedTransits[transitName];
    onTransitChange(updatedTransits);
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 105,
        right: 70,
        backgroundColor: 'rgba(53,53,54,0.9)',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '10px', mt: 1 }}>
      {transits.map((transit) => (
        <Button
            key={transit.name}
            onClick={() => !disabledTransits.includes(transit.name) && handleTransitToggle(transit.name)}
            disabled={disabledTransits.includes(transit.name)}
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: transitStates[transit.name] ? '#4fc3f7' : 'white',
            opacity: disabledTransits.includes(transit.name) ? 0.5 : 1,
            '&:hover': {
                backgroundColor: disabledTransits.includes(transit.name) ? 'transparent' : 'rgba(255,255,255,0.1)',
            },
            '&.Mui-disabled': {
                color: 'white',
            },
            }}
        >
            <Box
            sx={{
                width: 40,
                height: 40,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: transitStates[transit.name] ? 'rgba(79,195,247,0.2)' : 'rgba(255,255,255,0.2)',
                border: transitStates[transit.name] ? '2px solid #4fc3f7' : 'none',
                opacity: disabledTransits.includes(transit.name) ? 0.5 : 1,
            }}
            >
            <transit.icon sx={{ fontSize: 24 }} />
            </Box>
            <Typography 
            variant="caption" 
            sx={{ 
                mt: 1, 
                fontSize: '10px',
                color: transitStates[transit.name] ? '#4fc3f7' : 'white',
                opacity: disabledTransits.includes(transit.name) ? 0.5 : 1,
            }}
            >
            {transit.label}
            </Typography>
        </Button>
        ))}
      </Box>
    </Box>
  );
};

export default TransitPanel;