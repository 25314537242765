import { useEffect, useState, useCallback, useRef } from 'react';
import * as atlas from 'azure-maps-control';

const MapShipmentsRoutes = ({ map, mapStyle, zoomTo, zoomScale = 3, showAlternativeRoutes, onDCSelect, showOriginalRoutes, onLayersCreated }) => {
    const [deploymentsData, setDeploymentsData] = useState(null);
    const originalLayersRef = useRef([]);
    const alternativeLayersRef = useRef([]);
    const originalMarkersRef = useRef([]);
    const alternativeMarkersRef = useRef([]);
    const pinMarkersRef = useRef([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                const [shippingRoutes] = await Promise.all([
                    import('../data/shipments.json')
                ]);
                setDeploymentsData(shippingRoutes.default);
            } catch (error) {
                console.error('Error loading data:', error);
            }
        };
        loadData();
    }, []);

    const zoomIn= useCallback(() => {
        if (map) {
            const currentZoom = map.getCamera().zoom;
            
            map.setCamera({
                zoom: currentZoom + 0.2,
                type: 'ease',
                duration: 300,
                easingFunction: 'easeOutCubic'
            });
        }
      }, [map]);
      
      
      useEffect(() => {
        if (map) {
          zoomIn();
        }
      }, [map, zoomIn]);
      


    useEffect(() => {
        if (!map || !mapStyle || !zoomTo) return;
        map.setStyle({ style: mapStyle });
        if (map && zoomTo) {
            map.setCamera({
                center: zoomTo,
                zoom: zoomScale,
                type: 'ease',
                duration: 100,
                easingFunction: 'easeOutCubic'
            });
        }
    }, [map, mapStyle, zoomTo, zoomScale]);

    useEffect(() => {
        if (!map) return;
        const addImageIfNotExists = (imageName, imageUrl) => {
            if (!map.imageSprite.hasImage(imageName)) {
                return map.imageSprite.add(imageName, imageUrl);
            }
            return Promise.resolve();
        };
        Promise.all([
            addImageIfNotExists('customRedCircle', '../images/icon_circle_red.png'),
            addImageIfNotExists('customYellowCircle', '../images/icon_circle_yellow.png'),
            addImageIfNotExists('customBlueCircle', '../images/icon_circle_blue.png'),
            addImageIfNotExists('customGreenCircle', '../images/icon_circle_green.png'),
            addImageIfNotExists('customArrow', '../images/icon_arrow_blue.png')
        ]).then(() => {
            console.log('Images loaded successfully');
        }).catch((error) => {
            console.error('Error loading images:', error);
        });
    }, [map]);

    

    const showDeploymentTransit = useCallback((data) => {
        if (!map) return;

        const originalSource = new atlas.source.DataSource();
        const originalDashedSource = new atlas.source.DataSource();
        const originalPointsSource = new atlas.source.DataSource();
        const alternativeSource = new atlas.source.DataSource();
        const alternativeDashedSource = new atlas.source.DataSource();
        const alternativePointsSource = new atlas.source.DataSource();

        const originalLayer = new atlas.layer.LineLayer(originalSource, 'originalLayer', {
            strokeColor: 'red',
            strokeWidth: 4,
            lineJoin: 'round',
            lineCap: 'round'
        });

        const originalDashedLayer = new atlas.layer.LineLayer(originalDashedSource, 'originalDashedLayer', {
            strokeColor: 'yellow',
            strokeWidth: 4,
            strokeDashArray: [2, 4],
            allowOverlap: true,
            lineJoin: 'round',
            lineCap: 'round'
        });

        const alternativeLayer = new atlas.layer.LineLayer(alternativeSource, 'alternativeLayer', {
            strokeColor: 'green',
            strokeWidth: 4,
            lineJoin: 'round',
            lineCap: 'round'
        });

        const alternativeDashedLayer = new atlas.layer.LineLayer(alternativeDashedSource, 'alternativeDashedLayer', {
            strokeColor: 'yellow',
            strokeWidth: 4,
            strokeDashArray: [2, 4],
            allowOverlap: true,
            lineJoin: 'round',
            lineCap: 'round'
        });

        const originalCircleLayer = new atlas.layer.SymbolLayer(originalPointsSource, 'originalCircleLayer', {
            iconOptions: {
                image: 'customBlueCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true  
            },
            filter: ['==', ['get', 'type'], 'start']
        });

        const originalRedCircleLayer = new atlas.layer.SymbolLayer(originalPointsSource, 'originalRedCircleLayer', {
            iconOptions: {
                image: 'customRedCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'end']
        });

        const originalYellowCircleLayer = new atlas.layer.SymbolLayer(originalPointsSource, 'originalYellowCircleLayer', {
            iconOptions: {
                image: 'customYellowCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'alt']
        });

        const alternativeCircleLayer = new atlas.layer.SymbolLayer(alternativePointsSource, 'alternateCircleLayer', {
            iconOptions: {
                image: 'customBlueCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true  
            },
            filter: ['==', ['get', 'type'], 'start']
        });

        const alternativeRedCircleLayer = new atlas.layer.SymbolLayer(alternativePointsSource, 'alternativeRedCircleLayer', {
            iconOptions: {
                image: 'customRedCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'end']
        });

        const alternativeYellowCircleLayer = new atlas.layer.SymbolLayer(alternativePointsSource, 'alternativeYellowCircleLayer', {
            iconOptions: {
                image: 'customYellowCircle',
                size: 0.1,
                anchor: 'center',
                allowOverlap: true
            },
            filter: ['==', ['get', 'type'], 'alt']
        });
        
        map.sources.add(originalSource);
        map.sources.add(originalDashedSource);
        map.sources.add(originalPointsSource);
        map.sources.add(alternativeSource);
        map.sources.add(alternativeDashedSource);
        map.sources.add(alternativePointsSource);

        map.layers.add(originalLayer);
        map.layers.add(originalDashedLayer);
        map.layers.add(alternativeLayer);
        map.layers.add(alternativeDashedLayer);
        map.layers.add(originalCircleLayer);
        map.layers.add(originalRedCircleLayer);
        map.layers.add(originalYellowCircleLayer);
        map.layers.add(alternativeCircleLayer);
        map.layers.add(alternativeRedCircleLayer);
        map.layers.add(alternativeYellowCircleLayer);

        originalLayersRef.current = [originalLayer, originalDashedLayer, originalCircleLayer, originalRedCircleLayer, originalYellowCircleLayer, originalPointsSource];
        originalMarkersRef.current = [];
        
        alternativeLayersRef.current = [alternativeLayer, alternativeDashedLayer, alternativeCircleLayer, alternativeRedCircleLayer, alternativeYellowCircleLayer, alternativePointsSource];
        alternativeMarkersRef.current = [];
        
        data.datacenter.deployments.forEach((deployment) => {
            deployment.shipments.forEach((shipment) => {
                const startCoords = [shipment.source.geolocation.lon, shipment.source.geolocation.lat];
                const endCoords = [shipment.target.geolocation.lon, shipment.target.geolocation.lat];

                let routeCoordinates;
                if (shipment.target_path && shipment.target_path.length > 0) {
                    routeCoordinates = shipment.target_path;
                } else {
                    routeCoordinates = [startCoords, endCoords];
                }

                const path = new atlas.data.LineString(routeCoordinates);
                originalSource.add(path);

                originalPointsSource.add(new atlas.data.Feature(new atlas.data.Point(startCoords), { type: 'start' }));
                originalPointsSource.add(new atlas.data.Feature(new atlas.data.Point(endCoords), { type: 'end' }));

                var midpoint = routeCoordinates[Math.floor(routeCoordinates.length / 2)];
                
                let imgType = '<img src="../images/icon_boat_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                if (shipment.target.type === 'airport') {
                    imgType = '<img src="../images/icon_plane_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                } else if (shipment.target.type === 'train') {
                    imgType = '<img src="../images/icon_train_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                }

                const marker = new atlas.HtmlMarker({
                    position: midpoint,
                    htmlContent: imgType
                });

                map.markers.add(marker);
                originalMarkersRef.current.push(marker);

                if (shipment.target_alt) {
                    const altEndCoords = [shipment.target_alt.geolocation.lon, shipment.target_alt.geolocation.lat];
                    const altLine = new atlas.data.LineString([startCoords, altEndCoords]);
                    
                    alternativePointsSource.add(new atlas.data.Feature(new atlas.data.Point(startCoords), { type: 'start' }));
                    
                    alternativeSource.add(altLine);
                    alternativePointsSource.add(new atlas.data.Feature(new atlas.data.Point(altEndCoords), { type: 'alt' }));

                    const midPoint = pointAtPercentage(startCoords, altEndCoords, 50);

                    let imgType = '<img src="../images/icon_boat_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    if (shipment.target_alt.type === 'airport') {
                        imgType = '<img src="../images/icon_plane_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    } else if (shipment.target_alt.type === 'train') {
                        imgType = '<img src="../images/icon_train_grey_1.png" style="width:40px;height:40px;margin:3px;" />';
                    }

                    const marker = new atlas.HtmlMarker({
                        position: midPoint,
                        htmlContent: imgType
                    });

                    map.markers.add(marker);
                    alternativeMarkersRef.current.push(marker);
                }

                if (shipment.destination_hub) {
                    const altEndCoords = [shipment.target_alt.geolocation.lon, shipment.target_alt.geolocation.lat];
                    const destHubCoords = [shipment.destination_hub.geolocation.lon, shipment.destination_hub.geolocation.lat];
                    const hubLine = new atlas.data.LineString([endCoords, destHubCoords]);
                    const altHubLine = new atlas.data.LineString([altEndCoords, destHubCoords]);
                    
                    originalDashedSource.add(hubLine);
                    alternativeDashedSource.add(altHubLine);
                }

                if (shipment.destination_final) {
                    const destHubCoords = [shipment.destination_hub.geolocation.lon, shipment.destination_hub.geolocation.lat];
                    const destFinalCoords = [shipment.destination_final.geolocation.lon, shipment.destination_final.geolocation.lat];
                    const finalLineHub = new atlas.data.LineString([destHubCoords, destFinalCoords]);
                    
                    originalDashedSource.add(finalLineHub);
                    alternativeDashedSource.add(finalLineHub);

                    const pinMarker = new atlas.HtmlMarker({
                        color: 'DodgerBlue',
                        text: 'DC',
                        position: destFinalCoords
                    });
                    map.markers.add(pinMarker);
                    pinMarkersRef.current.push(pinMarker);

                    map.events.add('click', pinMarker, () => {
                        const facilityData = {
                            FacilityName: shipment.destination_final.properties.FACILITY,
                            FacilityCoordinates: {
                                long: destFinalCoords[0],
                                lat: destFinalCoords[1]
                            },
                            FacilityAddress: {
                                street: shipment.destination_final.properties.STREETADDRESS1,
                                street2: shipment.destination_final.properties.STREETADDRESS2,
                                city: shipment.destination_final.properties.SHIPPINGCITY,
                                state: shipment.destination_final.properties.SHIPPINGSTATE,
                                postalCode: shipment.destination_final.properties.SHIPPINGZIPCODE,
                                country: shipment.destination_final.properties.COUNTRY
                            }
                        };
                        onDCSelect(facilityData);
                    });
                }
            });
        });

        onLayersCreated({
            originalLayers: originalLayersRef.current,
            originalMarkers: originalMarkersRef.current,
            alternativeLayers: alternativeLayersRef.current,
            alternativeMarkers: alternativeMarkersRef.current,
            pinMarkers: pinMarkersRef.current
        });

        setLayersVisibility();

    }, [map, onLayersCreated]);

    useEffect(() => {
        if (map && deploymentsData) {
            showDeploymentTransit(deploymentsData);
        }
    }, [map, deploymentsData, showDeploymentTransit]);

    const setLayersVisibility = () => {
        
        originalLayersRef.current.forEach(layer => {
            if (layer.getOptions) {
                layer.setOptions({ visible: showOriginalRoutes });
            } 
        });

        originalMarkersRef.current.forEach(marker => {
            if (marker.getOptions) {
                marker.setOptions({ visible: showOriginalRoutes });
            } 
        });

        alternativeLayersRef.current.forEach(layer => {
            if (layer.getOptions) {
                layer.setOptions({ visible: showAlternativeRoutes });
            } 
        });
        
        alternativeMarkersRef.current.forEach(marker => {
            if (marker.getOptions) {
                marker.setOptions({ visible: showAlternativeRoutes });
            } 
        });
    }

    const pointAtPercentage = useCallback((startCoords, endCoords, percentage) => {
        percentage = Math.max(0, Math.min(100, percentage)) / 100;
        
        const lon = startCoords[0] + (endCoords[0] - startCoords[0]) * percentage;
        const lat = startCoords[1] + (endCoords[1] - startCoords[1]) * percentage;
        
        return [lon, lat];
    }, []);

    return null;
};

export default MapShipmentsRoutes;