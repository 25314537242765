import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import SatelliteIcon from '@mui/icons-material/Satellite';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import NightsStayIcon from '@mui/icons-material/NightsStay';

const MapStylePanel = ({ activeStyle, onStyleChange }) => {
  const styles = [
    { name: 'roads', icon: MapIcon },
    { name: 'satellite', icon: SatelliteIcon },
    { name: 'cesium', icon: ThreeDRotationIcon },
    { name: 'night', icon: NightsStayIcon },
  ];

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 70, 
        backgroundColor: 'rgba(53,53,54,0.9)',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row', 
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {styles.map((style) => (
        <Button
          key={style.name}
          onClick={() => onStyleChange(style.name)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            backgroundColor: 'transparent',
            color: activeStyle === style.name ? '#4fc3f7' : 'white',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.1)',
            },
          }}
        >
          <Box
            sx={{
              width: 40,
              height: 40,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255,255,255,0.2)',
              marginBottom: '5px',
            }}
          >
            <style.icon sx={{ fontSize: 24, color: activeStyle === style.name ? '#4fc3f7' : 'white' }} />
          </Box>
          <Typography variant="caption">{style.name}</Typography>
        </Button>
      ))}
    </Box>
  );
};

export default MapStylePanel;