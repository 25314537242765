import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  FormControlLabel,
  Switch,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Drawer,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "./UserContext";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db, storage } from "../firebase";
import FacilityResult from "./FacilityResult";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";

const UserProfilePanel = ({
  handleSignOut,
  datacentersData,
  handleExitClick,
  handleMenuClick,
}) => {
  const { user, userPreferences, setUserPreferences } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          setProfileImageUrl(userDoc.data().profileImage);
        }
      }
    };

    fetchProfileImage();
  }, [user]);

  const togglePanel = () => setIsPanelOpen(!isPanelOpen);

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedFacility(null);
  };

  const handleAddDatacenter = () => {
    if (
      selectedFacility &&
      userPreferences.facilitiesSettings.datacenterPreLoad.length < 3
    ) {
      const newDatacenter = {
        displayName: selectedFacility.properties.FACILITY,
        value: selectedFacility.geometry.coordinates,
      };
      const updatedDatacenterPreLoad = [
        ...userPreferences.facilitiesSettings.datacenterPreLoad,
        newDatacenter,
      ];
      setUserPreferences({
        ...userPreferences,
        facilitiesSettings: {
          ...userPreferences.facilitiesSettings,
          datacenterPreLoad: updatedDatacenterPreLoad,
        },
      });
      handleCloseDialog();
    }
  };

  const handleDeleteDatacenter = () => {
    const updatedDatacenterPreLoad =
      userPreferences.facilitiesSettings.datacenterPreLoad.filter(
        (_, index) =>
          !userPreferences.facilitiesSettings.selectedDatacenters.includes(
            index
          )
      );
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        datacenterPreLoad: updatedDatacenterPreLoad,
        selectedDatacenters: [],
      },
    });
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedDatacenters =
      userPreferences.facilitiesSettings.selectedDatacenters.includes(index)
        ? userPreferences.facilitiesSettings.selectedDatacenters.filter(
            (i) => i !== index
          )
        : [...userPreferences.facilitiesSettings.selectedDatacenters, index];
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        selectedDatacenters: updatedSelectedDatacenters,
      },
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    saveUserPreferences();
  };

  const handleSignOutClick = () => {
    handleSignOut();
    handleClose();
  };

  const handleShowFullMapChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showFullMap: event.target.checked,
      },
    });
  };

  const handleShowSeaportsChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showSeaports: event.target.checked,
      },
    });
  };

  const handleShowAirportsChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        showAirports: event.target.checked,
      },
    });
  };

  const handleDefaultViewChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      facilitiesSettings: {
        ...userPreferences.facilitiesSettings,
        defaultView: event.target.value,
      },
    });
  };

  const handleMapDefaultStyleChange = (event) => {
    setUserPreferences({
      ...userPreferences,
      defaultSettings: {
        ...userPreferences.defaultSettings,
        mapDefaultStyle: event.target.value,
      },
    });
  };

  const saveUserPreferences = async () => {
    if (user) {
      try {
        const userRef = doc(db, "users", user.uid);
        await setDoc(
          userRef,
          {
            defaultSettings: userPreferences.defaultSettings,
            facilitiesSettings: userPreferences.facilitiesSettings,
          },
          { merge: true }
        );
      } catch (error) {
        console.error("Error saving user preferences:", error);
      } finally {
      }
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!image) {
      alert("Choose an image first");
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      alert("User is not logged in");
      return;
    }

    const storageRef = ref(storage, `images/${user.uid}/${uuidv4()}`);

    try {
      await uploadBytes(storageRef, image);

      const downloadURL = await getDownloadURL(storageRef);
      setImageUrl(downloadURL);
      await setDoc(
        doc(db, "users", user.uid),
        { profileImage: downloadURL },
        { merge: true }
      );

      setProfileImageUrl(downloadURL);
    } catch (error) {
      console.error("Error during loading image", error);
    }
  };

  const getInitials = (name) => {
    if (!name) return "D";
    return name.charAt(0).toUpperCase();
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleChangeProfile = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Avatar
        onClick={togglePanel}
        src={profileImageUrl}
        sx={{
          cursor: "pointer",
          position: "absolute",
          top: "14px",
          right: "14px",
          backgroundColor: "#BFECFF",
          color: "black",
          zIndex: 1100,
        }}
      >
        {getInitials(user?.displayName)}
      </Avatar>
      <Drawer
        anchor="right"
        open={isPanelOpen}
        onClose={togglePanel}
        PaperProps={{
          sx: {
            width: 300,
            top: "74px",
            height: "calc(100% - 74px)",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Box
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={handleChangeProfile}
              sx={{ display: "inline-block", cursor: "pointer" }}
            >
              <Avatar
                sx={{
                  cursor: "pointer",
                  backgroundColor: "#BFECFF",
                  color: "black",
                }}
                onClick={handleChangeProfile}
                src={isHovered ? null : profileImageUrl}
              >
                {isHovered ? (
                  <EditIcon />
                ) : profileImageUrl ? null : (
                  getInitials(user?.displayName)
                )}
              </Avatar>
            </Box>
            <Box>
              <Typography variant="subtitle1">
                {user?.displayName ? user?.displayName : "Demo (name not set)"}
              </Typography>
              <Typography variant="body2">{user?.email}</Typography>
            </Box>
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleExitClick}
            sx={{
              mr: 1,
              backgroundColor: "#BFECFF",
              color: "black",
              borderRadius: "20px",
            }}
          >
            Exit
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.reload();
            }}
            sx={{
              mr: 1,
              backgroundColor: "#BFECFF",
              color: "black",
              borderRadius: "20px",
            }}
          >
            reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMenuClick}
            sx={{
              mr: 1,
              backgroundColor: "#BFECFF",
              color: "black",
              borderRadius: "20px",
            }}
          >
            Menu
          </Button>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">User Preferences</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showFullMap}
                onChange={handleShowFullMapChange}
              />
            }
            label="Show Full Map"
          />
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showSeaports}
                onChange={handleShowSeaportsChange}
              />
            }
            label="Show Seaports"
          />
          <FormControlLabel
            control={
              <Switch
                checked={userPreferences.defaultSettings.showAirports}
                onChange={handleShowAirportsChange}
              />
            }
            label="Show Airports"
          />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Map Default Style</Typography>
            <Select
              fullWidth
              value={userPreferences.defaultSettings.mapDefaultStyle}
              onChange={handleMapDefaultStyleChange}
            >
              <MenuItem value="satellite">Satellite</MenuItem>
              <MenuItem value="roads">Roads</MenuItem>
              <MenuItem value="night">Night</MenuItem>
            </Select>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Facilities Default View</Typography>
            <Select
              fullWidth
              value={userPreferences.facilitiesSettings.defaultView}
              onChange={handleDefaultViewChange}
            >
              <MenuItem value="satellite">Satellite</MenuItem>
              <MenuItem value="cesium">Cesium 3D</MenuItem>
              <MenuItem value="roads">Roads</MenuItem>
              <MenuItem value="night">Night</MenuItem>
            </Select>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h6">Cesium Preloading</Typography>
          {userPreferences.facilitiesSettings.datacenterPreLoad.map(
            (datacenter, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={userPreferences.facilitiesSettings.selectedDatacenters.includes(
                      index
                    )}
                    onChange={() => handleCheckboxChange(index)}
                  />
                }
                label={datacenter.displayName}
              />
            )
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              mx: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={handleOpenDialog}
              disabled={
                userPreferences.facilitiesSettings.datacenterPreLoad.length >= 3
              }
              sx={{
                flex: 1,
                mr: "3px",
                backgroundColor: "#BFECFF",
                color: "black",
                "&:hover": {
                  backgroundColor: "#5a5959",
                },
                "&:disabled": {
                  backgroundColor: "#ccc",
                  color: "#666",
                },
                borderRadius: "20px",
              }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteDatacenter}
              disabled={
                !userPreferences.facilitiesSettings.selectedDatacenters.length
              }
              sx={{
                flex: 1,
                ml: "3px",
                backgroundColor: "#BFECFF",
                color: "black",
                "&:hover": {
                  backgroundColor: "#5a5959",
                },
                "&:disabled": {
                  backgroundColor: "#ccc",
                  color: "#666",
                },
                borderRadius: "20px",
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleSignOutClick}>Sign Out</MenuItem>
      </Menu>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography variant="h6">Select Datacenter</Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          
          <FacilityResult
            onFacilitySelect={setSelectedFacility}
            selectedFacility={selectedFacility}
            mode="datacenters"
            onClearFilters={{}}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAddDatacenter}
            disabled={
              !selectedFacility ||
              userPreferences.facilitiesSettings.datacenterPreLoad.length >= 3
            }
            sx={{
              backgroundColor: "#BFECFF",
              color: "black",
              "&:hover": {
                backgroundColor: "#5a5959",
              },
            }}
          >
            Add
          </Button>
          <Button
            onClick={handleCloseDialog}
            sx={{
              backgroundColor: "#BFECFF",
              color: "black",
              "&:hover": {
                backgroundColor: "#5a5959",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {modalOpen && (
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle>Change Profile Picture</DialogTitle>
          <DialogContent>
            <input type="file" onChange={handleImageChange} />
            <Button onClick={handleUpload}>Upload</Button>
            {imageUrl && <img src={imageUrl} alt="Uploaded" />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Save</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default UserProfilePanel;
