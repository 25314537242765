import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#333333',
  color: 'white',
  fontSize: 14,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#1e1e1e',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#2e2e2e',
  },
}));

const ChatBubble = styled(Box)(({ theme, isUser }) => ({
  backgroundColor: isUser ? '#007bff' : '#333333',
  color: 'white',
  borderRadius: '18px',
  padding: '10px 15px',
  maxWidth: '70%',
  marginBottom: '10px',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
}));

const ShipmentsTracker = ({ onClose, showOriginalRoutes, showShippingAltRoutes, handleLayersVisibility }) => {
  const [filter, setFilter] = useState('all');
  const [selectedDeployment, setSelectedDeployment] = useState('all');
  const [message, setMessage] = useState('');
  const [isAlternativeRoute, setIsAlternativeRoute] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [isConfirmationRequested, setIsConfirmationRequested] = useState(false);
  const [isAlternativeRoutePermanent, setIsAlternativeRoutePermanent] = useState(false);
  const chatContainerRef = useRef(null);

  const shipments = [
    { id: '2001', destination: 'Boston BOS32', arrivalPort: 'NY/NJ, USA', departurePort: 'Antwerp, BE', originalDate: '10/02/2004', currentDate: isAlternativeRoute ? '10/5/2024' : 'N/A', delay: isAlternativeRoute ? '3 days' : '2+ weeks' },
    { id: '3459', destination: 'Boston BOS32', arrivalPort: 'NY/NJ, USA', departurePort: 'Rotterdam, NL', originalDate: '10/04/2004', currentDate: isAlternativeRoute ? '10/7/2024' : 'N/A', delay: isAlternativeRoute ? '3 days' : '2+ weeks' },
    { id: '4112', destination: 'Boydton BN14', arrivalPort: 'Savennah, USA', departurePort: 'Le Havre, FR', originalDate: '10/05/2004', currentDate: isAlternativeRoute ? '10/10/2024' : 'N/A', delay: isAlternativeRoute ? '5 days' : '2+ weeks' },
    { id: '2236', destination: 'San Antonio SAT12', arrivalPort: 'NY/NJ, USA', departurePort: 'Gioia Tauro, IT', originalDate: '10/02/2004', currentDate: isAlternativeRoute ? '10/12/2024' : 'N/A', delay: isAlternativeRoute ? '10 days' : '2+ weeks' },
  ];

  useEffect(() => {
    setChatMessages([
      { text: "Would you like to show the alternative routes recommended to solve the disruption? (please answer yes or no)", isUser: false }
    ]);
  }, []);

  useEffect(() => {
    if (isAlternativeRoutePermanent) {
      setIsAlternativeRoute(true);
      handleLayersVisibility(true, false);
    }
  }, [isAlternativeRoutePermanent, handleLayersVisibility]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleDeploymentChange = (event) => {
    setSelectedDeployment(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSendMessage = () => {
    if (message.trim().toLowerCase() === 'yes' && !isConfirmationRequested) {
      setChatMessages(prev => [...prev, { text: message, isUser: true }, { text: "Showing alternative routes! Type confirm to accept the alternative routes or cancel to continue...", isUser: false }]);
      setIsAlternativeRoute(true);
      handleLayersVisibility(true, false);
      setIsConfirmationRequested(true);
    } else if (isConfirmationRequested) {
      if (message.trim().toLowerCase() === 'confirm') {
        setChatMessages(prev => [...prev, { text: message, isUser: true }, { text: "Alternative routes confirmed and set as permanent.", isUser: false }]);
        setIsAlternativeRoutePermanent(true);
      } else if (message.trim().toLowerCase() === 'cancel') {
        setChatMessages(prev => [...prev, { text: message, isUser: true }, { text: "Alternative routes cancelled.", isUser: false }]);
        setIsAlternativeRoute(false);
        handleLayersVisibility(false, true);
      }
      setIsConfirmationRequested(false);
    } else {
      setChatMessages(prev => [...prev, { text: message, isUser: true }]);
    }
    setMessage('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleAlternativeRouteClick = () => {
    if (!isAlternativeRoutePermanent) {
      setIsAlternativeRoute(!isAlternativeRoute);
      handleLayersVisibility(!isAlternativeRoute, isAlternativeRoute);
    }
  };

  return (
    <Box sx={{ display: 'flex', height: '100%', backgroundColor: '#1e1e1e', position: 'relative' }}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          zIndex: 1,
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: 'calc(100% - 450px)', height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ padding: '8px 16px', backgroundColor: '#333333' }}>
          <Typography variant="h5" sx={{ fontSize: '24px', mb: 1, color: 'white' }}>
            Shipments 
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '190px', mr: 2 }} size="small">
              <InputLabel id="deployment-label" sx={{ color: 'white' }}>Select Shipment</InputLabel>
              <Select
                labelId="deployment-label"
                value={selectedDeployment}
                onChange={handleDeploymentChange}
                sx={{
                  color: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(255, 255, 255, 0.23)',
                  },
                }}
              >
                <MenuItem value="all">All</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Box sx={{ width: 20, height: 10, backgroundColor: 'red', mr: 1 }} />
                <Typography variant="caption" sx={{ color: 'white', mr: 2 }}>Disrupted</Typography>
                <Box sx={{ width: 20, height: 10, backgroundColor: 'green', mr: 1 }} />
                <Typography variant="caption" sx={{ color: 'white' }}>Alternative</Typography>
              </Box>
              <Button
                variant={isAlternativeRoute ? "outlined" : "contained"}
                onClick={handleAlternativeRouteClick}
                disabled={isAlternativeRoutePermanent}
                sx={{
                  mr: 2,
                  color: isAlternativeRoute ? 'white' : 'black',
                  borderColor: 'white'
                }}
              >
                Alternative Routes
              </Button>
              <FormControl sx={{ width: '120px' }} size="small">
                <InputLabel id="filter-label" sx={{ color: 'white' }}>Filter</InputLabel>
                <Select
                  labelId="filter-label"
                  value={filter}
                  onChange={handleFilterChange}
                  sx={{
                    color: 'white',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.23)',
                    },
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="ontime">On Time</MenuItem>
                  <MenuItem value="delayed">Delayed</MenuItem>
                  <MenuItem value="arrived">Arrived</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ flexGrow: 1, backgroundColor: '#1e1e1e' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Shipment #</StyledTableCell>
                <StyledTableCell>Final Destination</StyledTableCell>
                <StyledTableCell>Arrival Port</StyledTableCell>
                <StyledTableCell>Departure Port</StyledTableCell>
                <StyledTableCell>Original Port Arrival Date</StyledTableCell>
                <StyledTableCell>Current Port Arrival Date</StyledTableCell>
                <StyledTableCell>Current Delay</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipments.map((shipment) => (
                <StyledTableRow key={shipment.id}>
                  <TableCell component="th" scope="row" sx={{ color: 'white' }}>{shipment.id}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{shipment.destination}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{shipment.arrivalPort}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{shipment.departurePort}</TableCell>
                  <TableCell sx={{ color: 'white' }}>{shipment.originalDate}</TableCell>
                  <TableCell sx={{ color: isAlternativeRoute ? 'green' : 'red' }}>{shipment.currentDate}</TableCell>
                  <TableCell sx={{ color: isAlternativeRoute ? 'green' : 'red' }}>{shipment.delay}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ width: '450px', height: '100%', borderLeft: '1px solid #333333', display: 'flex', flexDirection: 'column' }}>
        <Box ref={chatContainerRef} sx={{ flexGrow: 1, overflow: 'auto', backgroundColor: '#1e1e1e', padding: '16px', display: 'flex', flexDirection: 'column' }}>
          {chatMessages.map((msg, index) => (
            <ChatBubble key={index} isUser={msg.isUser}>
              {msg.text}
            </ChatBubble>
          ))}
        </Box>
        <Box sx={{ padding: '16px', backgroundColor: '#1e1e1e' }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: '#2e2e2e', 
            borderRadius: '25px', 
            padding: '8px 16px'
          }}>
            <input
              type="text"
              value={message}
              onChange={handleMessageChange}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              style={{
                flex: 1,
                border: 'none',
                backgroundColor: 'transparent',
                color: 'white',
                outline: 'none',
                fontSize: '14px',
              }}
            />
            <IconButton 
              onClick={handleSendMessage}
              sx={{ 
                color: 'white', 
                backgroundColor: '#007bff', 
                '&:hover': { backgroundColor: '#0056b3' },
                padding: '8px',
                borderRadius: '50%',
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShipmentsTracker;