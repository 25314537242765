import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton, Popover } from '@mui/material';
import FacilityResult from './FacilityResult';
import FacilityFilter from './FacilityFilter';
import DatacenterInfo from './DatacenterInfo';
import StorageIcon from '@mui/icons-material/Storage';
import HubIcon from '@mui/icons-material/Hub';
import SupplierIcon from '@mui/icons-material/Warehouse';
import CircleIcon from '@mui/icons-material/Circle';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const NavigationButton = ({ icon: Icon, label, onClick, isActive }) => (
  <Button
    onClick={onClick}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: isActive ? '#2F2D32' : '#1D1B20',
      color: '#BFECFF',
      borderRadius: '10px',
      padding: '3px',
      minWidth: '70px',
      height: '70px',
      '&:hover': {
        backgroundColor: '#2F2D32',
      },
      '&:active': {
        backgroundColor: '#2F2D32',
      },
    }}
  >
    <Box sx={{ position: 'relative', width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircleIcon 
        sx={{
          position: 'absolute',
          fontSize: 32,
          color: isActive ? '#BFECFF' : 'transparent',
          transition: 'color 0.3s',
        }} 
      />
      <Icon 
        sx={{
          position: 'relative',
          zIndex: 1,
          fontSize: label === 'Suppliers' ? 28 : 24, 
          color: isActive ? '#000' : '#BFECFF',
          transition: 'color 0.3s',
        }} 
      />
    </Box>
    <Typography 
      variant="caption" 
      sx={{ 
        color: '#BFECFF', 
        mt: 0.5, 
        fontSize: '10px',
        textAlign: 'center'
      }}
    >
      {label}
    </Typography>
  </Button>
);

const FacilityComponent = ({ 
  showFacilityFinder,
  datacentersData, 
  onFacilitySelect, 
  selectedFacility, 
  showFacilityInfo, 
  setShowSeperateDeploymentsTable, 
  setShowLanduseMap,
  onClose, 
  handleFacilityInfoClose, 
  handleUnrealLevelClick, 
  mode 
}) => {
  const [selectedController, setSelectedController] = useState('datacenters');
  const [anchorEl, setAnchorEl] = useState(null);
  const [datacenterFilters, setDatacenterFilters] = useState({});
  const [currentFacilityData, setCurrentFacilityData] = useState(null);
  const [showDatacenterInfo, setShowDatacenterInfo] = useState(false);

  const handleFacilitySelect = (facility) => {
    const newFacilityData = {
      FacilityName: facility.properties.FACILITY,
      FacilityCoordinates: { 
        long: facility.geometry.coordinates[0],
        lat: facility.geometry.coordinates[1]
      },
      FacilityAddress: {
        street: facility.properties.STREETADDRESS1 || "",
        street2: facility.properties.STREETADDRESS2 || "",
        city: facility.properties.CITY || "",
        state: facility.properties.SHIPPINGSTATE || "",
        postalCode: facility.properties.SHIPPINGZIPCODE || "",
        country: facility.properties.COUNTRY || "",
        region: facility.properties.REGION || ""
      },
      FacilityType: facility.properties.FACILITYTYPE || "",
      FacilityLocation: `${facility.properties.CITY || ""}, ${facility.properties.COUNTRY || ""}`,
      FacilityStatus: facility.properties.FACILITYPHASE || "",
      FacilityCoolingType: facility.properties.COOLING || ""
    };

    setCurrentFacilityData(newFacilityData);
    setShowDatacenterInfo(true);
    onFacilitySelect(facility);
  };

  useEffect(() => {
    handleControllerClick('datacenters');
  }, []);

  useEffect(() => {
    if (showFacilityInfo && selectedFacility) {
      setCurrentFacilityData(selectedFacility);
      setShowDatacenterInfo(true);
    } 
  }, [showFacilityInfo, selectedFacility]);

  const handleControllerClick = (controller) => {
    setSelectedController(controller);
  };

  const handleMenuOpen = (event) => {
    if (selectedController === 'datacenters') {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterChange = (newFilters) => {
    setDatacenterFilters(newFilters);
    handleMenuClose();
  };

  const handleClearFilters = () => {
    setDatacenterFilters({});
  };

  const topNavItems = [
    { icon: StorageIcon, label: 'Data Centers', type: 'datacenters' },
    { icon: HubIcon, label: 'Hubs', type: 'hubs' },
    { icon: SupplierIcon, label: 'Suppliers', type: 'suppliers' },
  ];

  return (
    <Box sx={{ display: 'flex', height: '100%'}}>
      { showFacilityFinder && (
        <Box sx={{ backgroundColor: '#1D1B20', color: '#BFECFF', display: 'flex', flexDirection: 'column', height: 'calc(100% - 10px)', width: '300px', flexShrink: 0 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FmdGoodOutlinedIcon sx={{ color: '#BFECFF', marginRight: '8px' }} />
              <Typography variant="h6" sx={{ color: '#BFECFF' }}>
                Facilities Finder
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={handleMenuOpen} disabled={selectedController !== 'datacenters'}>
                <MoreVertIcon sx={{ color: selectedController === 'datacenters' ? '#BFECFF' : '#666666' }} />
              </IconButton>
              <IconButton onClick={onClose}>
                <CloseIcon sx={{ color: '#BFECFF' }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '10px', padding: '16px' }}>
            {topNavItems.map((item) => (
              <NavigationButton
                key={item.type}
                icon={item.icon}
                label={item.label}
                onClick={() => handleControllerClick(item.type)}
                isActive={selectedController === item.type}
              />
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: '10px' }}>
            <FacilityResult
              datacentersData={datacentersData}
              onFacilitySelect={handleFacilitySelect}
              selectedFacility={selectedFacility}
              mode={selectedController}
              filters={selectedController === 'datacenters' ? datacenterFilters : {}}
              onClearFilters={handleClearFilters}
            />
          </Box>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                backgroundColor: '#1D1B20',
                border: '1px solid #BFECFF',
                borderRadius: '4px',
                width: '100%',
                maxWidth: '265px',
                padding: '8px',
              }
            }}
          >
            <FacilityFilter
              onFilterChange={handleFilterChange}
              onCancel={handleMenuClose}
              onClear={handleClearFilters}
              mode={selectedController}
              currentFilters={datacenterFilters}
            />
          </Popover>
        </Box>
      )}
      <Box sx={{ width: '425px', flexShrink: 0 }}>
        {currentFacilityData && showFacilityInfo && showDatacenterInfo && (
          <DatacenterInfo
            geolocation={[currentFacilityData.FacilityCoordinates.long, currentFacilityData.FacilityCoordinates.lat]}
            onClose={() => { setShowDatacenterInfo(false); handleFacilityInfoClose();}}
            facilityData={currentFacilityData}
            setShowSeperateDeploymentsTable={setShowSeperateDeploymentsTable}
            setShowLanduseMap={setShowLanduseMap}
            switchToUE={handleUnrealLevelClick}
            mode={selectedController || 'default'}
          />
        )}
      </Box>
    </Box>
  );
};

export default FacilityComponent;