import React, { useState } from 'react';
import { Box, IconButton, Tooltip, ClickAwayListener } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import SatelliteIcon from '@mui/icons-material/Satellite';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PlaceIcon from '@mui/icons-material/Place';
import MapStylePanel from './MapStylePanel';
import TransitPanel from './TransitPanel';

const MapStyleController = ({ activeStyle, onStyleChange, onZoomIn, onZoomOut, transitStates, onTransitToggle, onTransitChange  }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isTransitPanelOpen, setIsTransitPanelOpen] = useState(false);

  const getActiveIcon = () => {
    switch (activeStyle) {
      case 'roads':
        return <MapIcon />;
      case 'satellite':
        return <SatelliteIcon />;
      case 'cesium':
        return <ThreeDRotationIcon />;
      case 'night':
        return <NightsStayIcon />;
      default:
        return <MapIcon />;
    }
  };

  const handleStyleChange = (style) => {
    onStyleChange(style);
    setIsPanelOpen(false);
  };

  const handleTransitChange = (updatedTransits) => {
    onTransitChange(updatedTransits);
    setIsTransitPanelOpen(false);
  }


  return (
    <ClickAwayListener onClickAway={() => setIsPanelOpen(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: 84,
          right: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
          backgroundColor: 'rgba(53,53,54,0.6)',
          borderRadius: '30px',
          padding: '10px',
        }}
      >
        <Tooltip title="Map Style" placement="left">
          <IconButton
            onClick={() => setIsPanelOpen(!isPanelOpen)}
            sx={{
              backgroundColor: 'rgba(255,255,255,0.2)',
              color: 'white',
              '&:hover': { backgroundColor: 'rgba(255,255,255,0.3)' },
            }}
          >
            {getActiveIcon()}
          </IconButton>
        </Tooltip>
        <Box sx={{ 
          backgroundColor: 'rgba(255,255,255,0.2)', 
          borderRadius: '15px', 
          display: 'flex', 
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <IconButton onClick={onZoomIn} sx={{ color: 'white', borderRadius: '15px 15px 0 0' }}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={onZoomOut} sx={{ color: 'white', borderRadius: '0 0 15px 15px' }}>
            <RemoveIcon />
          </IconButton>
        </Box>
        <IconButton onClick={() => setIsTransitPanelOpen(!isTransitPanelOpen)}  sx={{ backgroundColor: 'rgba(255,255,255,0.2)', color: 'white' }}>
          <PlaceIcon />
        </IconButton>
        {isPanelOpen && (
          <MapStylePanel
            activeStyle={activeStyle}
            onStyleChange={handleStyleChange}
          />
        )}
        {isTransitPanelOpen && (
          <TransitPanel
            transitStates={transitStates}
            onTransitChange={handleTransitChange}
            onClose={() => setIsTransitPanelOpen(false)}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default MapStyleController;