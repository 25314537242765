import React, { useState, useRef, useEffect } from 'react';
import { Box, Card, CardContent, Typography, IconButton} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const createData = (datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject) => {
  return { datacenter, supplier, origin, requested, scheduled, status, priority, facilityObject };
};

const facilityObjects = {
  'Quincy MWH06': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-119.8828, 47.23342]
    },
    "properties": {
        "FACILITY": "Quincy MWH06",
        "FACILITYSHORTCODE": "MWH06",
        "DCOPSREGION": "US West",
        "CITY": "Quincy",
        "OPERATIONALTAXONOMYID": "2",
        "IAMStaffGroupName": "MWH IAM Staff Group A",
        "OPERATIONALTAXONOMY": "Class A",
        "FACILITYPHASEID": "6",
        "FACILITYPHASE": "Operational",
        "HOURSOFOPERATION": "",
        "BILLINGTYPE": "Billable",
        "PLANNINGGROUPID": "MWH01",
        "COUNTRY": "United States",
        "TERRITORY": "North America",
        "REGION": "AMER",
        "REGIONGROUP": "DOMESTIC",
        "REGIONALDATACENTERMANAGER": "nasath",
        "LOGISTICSENGINEER": "nikonen;rimather",
        "LOGISTICSRECEIVINGTEAMEMAIL": "mwh06log",
        "FACILITYTYPEID": "31",
        "FACILITYTYPE": "Datacenter",
        "DCOPERATEDBY": "Mcio",
        "SPARESFACILITY": "514",
        "LOGISTICSCONSTRAINTS": "RequiresPackaging",
        "LOGISTICSRECEIVINGTEAMPHONE": "1 5097976797",
        "REGIONALINVENTORYANDASSETPROGRAMMANAGEREMAIL": "",
        "HOLIDAYS": "[{\"Item1\":\"2022-11-24\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2022-11-25\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2022-12-23\",\"Item2\":\"Christmas Eve (MSFT observed)\"},{\"Item1\":\"2022-12-24\",\"Item2\":\"Christmas Eve\"},{\"Item1\":\"2022-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2022-12-26\",\"Item2\":\"Christmas Day (MSFT Observed)\"},{\"Item1\":\"2023-01-01\",\"Item2\":\"New Year's Day\"},{\"Item1\":\"2023-01-02\",\"Item2\":\"New Year's Day (MSFT Observed)\"},{\"Item1\":\"2023-01-16\",\"Item2\":\"MLK Day\"},{\"Item1\":\"2023-02-20\",\"Item2\":\"President's Day\"},{\"Item1\":\"2023-05-29\",\"Item2\":\"Memorial Day\"},{\"Item1\":\"2023-07-04\",\"Item2\":\"Independence Day\"},{\"Item1\":\"2023-09-04\",\"Item2\":\"Labor Day\"},{\"Item1\":\"2023-11-23\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2023-11-24\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2023-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2023-12-26\",\"Item2\":\"Day After Christmas\"}]",
        "SHIPPINGSTATE": "WA",
        "SHIPPINGCITY": "Quincy",
        "SHIPPINGADDRESS": "1515 Port Industrial Way",
        "SHIPPINGZIPCODE": "98848-5002",
        "COMPANYCODE": "1010",
        "FACILITYOWNER": "Microsoft",
        "OMCSITESERVICESSUPPORTTYPE": "NotSupported",
        "COOLING": "Indirect Evaporative Cooling (Cooling Tower)",
        "FACILITYADDRESS": "1515 Port Industrial Way; c/o Microsoft; 98848-5002; Quincy; Washington; United States",
        "STREETADDRESS1": "1515 Port Industrial Way",
        "STREETADDRESS2": "c/o Microsoft",
        "WARRANTYLEARNERID": "PL72264946",
        "WARRANTYSHIPTO": "600962073",
        "WARRANTYTIMEZONE": "TZ14",
        "WARRANTYSOLDTO": "130134420",
        "SW_REGION_DETAILS": "US",
        "ISSPARED": "TRUE",
        "RWN": "1",
        "SparesPlantCode": "8129",
        "CurrentSparesSupportModel": "Supplier Warranted",
        "FutureSparesSupportModel": "Direct Warranted",
        "SparesTransitionDate": "6/1/2023",
        "EntityType": "maybeLate",
        "FACILITYGROUPNAME": "Quincy",
        "FACILITYGROUPSIZE": 9
      }
  },
  'Columbia 6': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [
        -119.850695,
        47.234154
      ]
    },
    "properties": {
        "FACILITY": "Columbia 6",
        "FACILITYSHORTCODE": "CO6",
        "DCOPSREGION": "US West",
        "CITY": "Quincy",
        "OPERATIONALTAXONOMYID": "2",
        "IAMStaffGroupName": "MWH IAM Staff Group A",
        "OPERATIONALTAXONOMY": "Class A",
        "FACILITYPHASEID": "6",
        "FACILITYPHASE": "Operational",
        "HOURSOFOPERATION": "",
        "BILLINGTYPE": "Billable",
        "PLANNINGGROUPID": "CO1",
        "COUNTRY": "United States",
        "TERRITORY": "North America",
        "REGION": "AMER",
        "REGIONGROUP": "DOMESTIC",
        "REGIONALDATACENTERMANAGER": "nasath",
        "LOGISTICSENGINEER": "nikonen;rimather",
        "LOGISTICSRECEIVINGTEAMEMAIL": "co6log",
        "FACILITYTYPEID": "31",
        "FACILITYTYPE": "Datacenter",
        "DCOPERATEDBY": "Mcio",
        "SPARESFACILITY": "144",
        "LOGISTICSCONSTRAINTS": "",
        "LOGISTICSRECEIVINGTEAMPHONE": "1 5097876818",
        "REGIONALINVENTORYANDASSETPROGRAMMANAGEREMAIL": "",
        "HOLIDAYS": "[{\"Item1\":\"2022-11-24\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2022-11-25\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2022-12-23\",\"Item2\":\"Christmas Eve (MSFT observed)\"},{\"Item1\":\"2022-12-24\",\"Item2\":\"Christmas Eve\"},{\"Item1\":\"2022-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2022-12-26\",\"Item2\":\"Christmas Day (MSFT Observed)\"},{\"Item1\":\"2023-01-01\",\"Item2\":\"New Year's Day\"},{\"Item1\":\"2023-01-02\",\"Item2\":\"New Year's Day (MSFT Observed)\"},{\"Item1\":\"2023-01-16\",\"Item2\":\"MLK Day\"},{\"Item1\":\"2023-02-20\",\"Item2\":\"President's Day\"},{\"Item1\":\"2023-05-29\",\"Item2\":\"Memorial Day\"},{\"Item1\":\"2023-07-04\",\"Item2\":\"Independence Day\"},{\"Item1\":\"2023-09-04\",\"Item2\":\"Labor Day\"},{\"Item1\":\"2023-11-23\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2023-11-24\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2023-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2023-12-26\",\"Item2\":\"Day After Christmas\"}]",
        "SHIPPINGSTATE": "WA",
        "SHIPPINGCITY": "Quincy",
        "SHIPPINGADDRESS": "501 Port Industrial Pkwy",
        "SHIPPINGZIPCODE": "98848",
        "COMPANYCODE": "1010",
        "FACILITYOWNER": "Microsoft",
        "OMCSITESERVICESSUPPORTTYPE": "NotSupported",
        "COOLING": "Direct Evaporative Cooling (Adiabatic)",
        "FACILITYADDRESS": "501 Port Industrial Pkwy; Quincy; WA 98848-1074",
        "STREETADDRESS1": "501 Port Industrial Pkwy",
        "STREETADDRESS2": "c/o Microsoft",
        "WARRANTYLEARNERID": "PL72264946",
        "WARRANTYSHIPTO": "110738658",
        "WARRANTYTIMEZONE": "TZ14",
        "WARRANTYSOLDTO": "130134420",
        "SW_REGION_DETAILS": "US",
        "ISSPARED": "TRUE",
        "RWN": "1",
        "SparesPlantCode": "1538",
        "CurrentSparesSupportModel": "Supplier Warranted",
        "FutureSparesSupportModel": "Direct Warranted",
        "SparesTransitionDate": "6/1/2023",
        "EntityType": "onTime",
        "FACILITYGROUPNAME": "Columbia",
        "FACILITYGROUPSIZE": 6
      }
  },
  'Newark EWR31': {
    "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          -74.484724,
          40.556953
        ]
      },
      "properties": {
        "FACILITY": "Newark EWR31",
        "FACILITYSHORTCODE": "EWR31",
        "DCOPSREGION": "US East",
        "CITY": "Piscataway Township",
        "OPERATIONALTAXONOMYID": "5",
        "IAMStaffGroupName": "",
        "OPERATIONALTAXONOMY": "Class D",
        "FACILITYPHASEID": "6",
        "FACILITYPHASE": "Operational",
        "HOURSOFOPERATION": "",
        "BILLINGTYPE": "Billable",
        "PLANNINGGROUPID": "EWR31",
        "COUNTRY": "United States",
        "TERRITORY": "North America",
        "REGION": "AMER",
        "REGIONGROUP": "DOMESTIC",
        "REGIONALDATACENTERMANAGER": "payantz",
        "LOGISTICSENGINEER": "shtay",
        "LOGISTICSRECEIVINGTEAMEMAIL": "ewr31log",
        "FACILITYTYPEID": "31",
        "FACILITYTYPE": "Datacenter",
        "DCOPERATEDBY": "Mcio",
        "SPARESFACILITY": "3134",
        "LOGISTICSCONSTRAINTS": "RequiresPackaging ; ScheduledPriorToShipment",
        "LOGISTICSRECEIVINGTEAMPHONE": "1 4257042046",
        "REGIONALINVENTORYANDASSETPROGRAMMANAGEREMAIL": "shtay",
        "HOLIDAYS": "[{\"Item1\":\"2022-11-24\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2022-11-25\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2022-12-23\",\"Item2\":\"Christmas Eve (MSFT observed)\"},{\"Item1\":\"2022-12-24\",\"Item2\":\"Christmas Eve\"},{\"Item1\":\"2022-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2022-12-26\",\"Item2\":\"Christmas Day (MSFT Observed)\"},{\"Item1\":\"2023-01-01\",\"Item2\":\"New Year's Day\"},{\"Item1\":\"2023-01-02\",\"Item2\":\"New Year's Day (MSFT Observed)\"},{\"Item1\":\"2023-01-16\",\"Item2\":\"MLK Day\"},{\"Item1\":\"2023-02-20\",\"Item2\":\"President's Day\"},{\"Item1\":\"2023-05-29\",\"Item2\":\"Memorial Day\"},{\"Item1\":\"2023-07-04\",\"Item2\":\"Independence Day\"},{\"Item1\":\"2023-09-04\",\"Item2\":\"Labor Day\"},{\"Item1\":\"2023-11-23\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2023-11-24\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2023-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2023-12-26\",\"Item2\":\"Day After Christmas\"}]",
        "SHIPPINGSTATE": "NJ",
        "SHIPPINGCITY": "Piscataway Township",
        "SHIPPINGADDRESS": "101 Possumtown Rd.",
        "SHIPPINGZIPCODE": "08854-3706",
        "COMPANYCODE": "1010",
        "FACILITYOWNER": "QTS",
        "OMCSITESERVICESSUPPORTTYPE": "All",
        "COOLING": "Chilled Water - Water Cooled Chillers",
        "FACILITYADDRESS": "101 Possumtown Rd; Piscataway Township; NJ 08854; USA",
        "STREETADDRESS1": "101 Possumtown Rd",
        "STREETADDRESS2": "c/o Quality investment; LLC",
        "WARRANTYLEARNERID": "",
        "WARRANTYSHIPTO": "",
        "WARRANTYTIMEZONE": "",
        "WARRANTYSOLDTO": "",
        "SW_REGION_DETAILS": "US",
        "ISSPARED": "FALSE",
        "RWN": "1",
        "SparesPlantCode": "",
        "CurrentSparesSupportModel": "Not Supported",
        "FutureSparesSupportModel": "",
        "SparesTransitionDate": "",
        "EntityType": "onTime",
        "FACILITYGROUPNAME": "Newark",
        "FACILITYGROUPSIZE": 1
      }
  },
  'Boston BOS32': {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": [-71.219038, 42.301218]
    },
    "properties": {
        "FACILITY": "Boston BOS32",
        "FACILITYSHORTCODE": "BOS32",
        "DCOPSREGION": "US East",
        "CITY": "NEEDHAM HEIGHTS",
        "OPERATIONALTAXONOMYID": "5",
        "IAMStaffGroupName": "",
        "OPERATIONALTAXONOMY": "Class D",
        "FACILITYPHASEID": "6",
        "FACILITYPHASE": "Operational",
        "HOURSOFOPERATION": "",
        "BILLINGTYPE": "Billable",
        "PLANNINGGROUPID": "BOS32",
        "COUNTRY": "United States",
        "TERRITORY": "North America",
        "REGION": "AMER",
        "REGIONGROUP": "DOMESTIC",
        "REGIONALDATACENTERMANAGER": "payantz",
        "LOGISTICSENGINEER": "shtay",
        "LOGISTICSRECEIVINGTEAMEMAIL": "bos32log",
        "FACILITYTYPEID": "31",
        "FACILITYTYPE": "Datacenter",
        "DCOPERATEDBY": "Mcio",
        "SPARESFACILITY": "2996",
        "LOGISTICSCONSTRAINTS": "RequiresPackaging ; ScheduledPriorToShipment ; ProtectiveFloorCoveringRequired",
        "LOGISTICSRECEIVINGTEAMPHONE": "1 4257042046",
        "REGIONALINVENTORYANDASSETPROGRAMMANAGEREMAIL": "shtay",
        "HOLIDAYS": "[{\"Item1\":\"2022-11-24\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2022-11-25\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2022-12-23\",\"Item2\":\"Christmas Eve (MSFT observed)\"},{\"Item1\":\"2022-12-24\",\"Item2\":\"Christmas Eve\"},{\"Item1\":\"2022-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2022-12-26\",\"Item2\":\"Christmas Day (MSFT Observed)\"},{\"Item1\":\"2023-01-01\",\"Item2\":\"New Year's Day\"},{\"Item1\":\"2023-01-02\",\"Item2\":\"New Year's Day (MSFT Observed)\"},{\"Item1\":\"2023-01-16\",\"Item2\":\"MLK Day\"},{\"Item1\":\"2023-02-20\",\"Item2\":\"President's Day\"},{\"Item1\":\"2023-05-29\",\"Item2\":\"Memorial Day\"},{\"Item1\":\"2023-07-04\",\"Item2\":\"Independence Day\"},{\"Item1\":\"2023-09-04\",\"Item2\":\"Labor Day\"},{\"Item1\":\"2023-11-23\",\"Item2\":\"Thanksgiving Day\"},{\"Item1\":\"2023-11-24\",\"Item2\":\"Day after Thanksgiving\"},{\"Item1\":\"2023-12-25\",\"Item2\":\"Christmas Day\"},{\"Item1\":\"2023-12-26\",\"Item2\":\"Day After Christmas\"}]",
        "SHIPPINGSTATE": "MA",
        "SHIPPINGCITY": "Needham Heights",
        "SHIPPINGADDRESS": "128 1ST AVE",
        "SHIPPINGZIPCODE": "02494-2805",
        "COMPANYCODE": "1010",
        "FACILITYOWNER": "Digital Realty Trust",
        "OMCSITESERVICESSUPPORTTYPE": "All",
        "COOLING": "Chilled Water - Water Cooled Chillers with Waterside Economizer",
        "FACILITYADDRESS": "128 1st Ave; Needham; MA 02494; USA",
        "STREETADDRESS1": "128 1ST AVE",
        "STREETADDRESS2": "c/o ?Digital Realty Trust",
        "WARRANTYLEARNERID": "",
        "WARRANTYSHIPTO": "",
        "WARRANTYTIMEZONE": "",
        "WARRANTYSOLDTO": "",
        "SW_REGION_DETAILS": "US",
        "ISSPARED": "FALSE",
        "RWN": "1",
        "SparesPlantCode": "",
        "CurrentSparesSupportModel": "Not Supported",
        "FutureSparesSupportModel": "",
        "SparesTransitionDate": "",
        "EntityType": "defLate",
        "FACILITYGROUPNAME": "Boston",
        "FACILITYGROUPSIZE": 1
      }
  }
};

const rows = [
  createData('Quincy MWH06', 'Micron', 'USA', '05/10/2024', '08/12/2024', 'Delayed', '1', facilityObjects['Quincy MWH06']),
  createData('Newark EWR31', 'Samsung', 'S. Korea', '06/11/2024', '08/13/2024', 'On Time', '2', facilityObjects['Columbia 6']),
  createData('Columbia 6', 'Samsung', 'S. Korea', '06/11/2024', '08/13/2024', 'On Time', '2', facilityObjects['Newark EWR31']),
  createData('Boston BOS32', 'Samsung', 'S. Korea', '06/11/2024', '08/13/2024', 'On Time', '2', facilityObjects['Boston BOS32']),
];

const StyledCard = styled(Card)(({ theme }) => ({
  width: '350px',
  minHeight: '180px',
  backgroundColor: 'rgba(41,41,48,0.9)',
  color: 'white',
  borderRadius: theme.spacing(2),
  margin: '0 8px',
  flexShrink: 0,
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingBottom: '0px'
}));

const ClickableBox = styled(Box)(({ theme }) => ({
  border: '1px dashed rgba(41, 41, 48, 0.5)',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: 'rgba(116, 147, 214, 0.2)',
    color: 'grey'
  },
}));

const SliderContainer = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
  width: 'calc(100% - 60px)',
  position: 'relative',
  margin: '0 30px'
});

const ScrollButton = styled(IconButton)(({ theme, direction }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  [direction]: 0,
  zIndex: 2,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: 'white',
  width: '24px',
  height: '24px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));



const DeploymentsWindow = ({ onFacilitySelect, onHeightChange }) => {
  const containerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

 

  const handleScroll = (direction) => {
    const container = containerRef.current;
    if (container) {
      const cardWidth = 350 + 16;
      const scrollAmount = direction === 'right' ? cardWidth : -cardWidth;
      const newPosition = Math.max(0, Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth));
      container.scrollTo({ left: newPosition, behavior: 'smooth' });
      setScrollPosition(newPosition);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(scrollPosition < container.scrollWidth - container.clientWidth);
    }
  }, [scrollPosition]);

  return (
    <Box sx={{ position: 'relative', width: '100%', mt: 3, height: 'auto' }}>
      {showLeftArrow && (
        <ScrollButton direction="left" onClick={() => handleScroll('left')} sx={{left: '8px'}}>
          <ArrowBackIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
        </ScrollButton>
      )}
     <SliderContainer ref={containerRef}>
  {[0, 2].map((startIndex) => (
    <StyledCard key={startIndex}>
      <CardContent sx={{ p: 2 }}>
        <Box sx={{mb: 1}}>
          <Typography variant="h6">{startIndex === 0 ? "US West" : "US East"}</Typography>
        </Box>
        {[0, 1].map((offset) => {
          const index = startIndex + offset;
          const row = rows[index];
          return (
            <ClickableBox 
              key={index}
              onClick={() => onFacilitySelect(row.facilityObject)}
              sx={{
                '&:hover': {
                  '& .deployment-text': {
                    color: 'white',
                  },
                },
                mb: offset === 0 ? 2 : 0,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start', ml: 0 }}>
                <Box sx={{ textAlign: 'center', mr: 2,  border: '1px solid rgba(255, 255, 248, 0.5)', borderRadius: 0.4 }}>
                  <Typography variant="caption" sx={{ color: 'grey.500', fontSize: '0.7rem', p:1.2 }}>AUG</Typography>
                  <Typography variant="h5" sx={{ lineHeight: 1, pb: 0.4}}>{index === 0 ? '9' : index === 1 ? '23' : index === 2 ? '25' : '30'}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6" className="deployment-text" sx={{ fontSize: '1.1rem', transition: 'color 0.3s' }}>
                    {index === 0 ? 'MWH23' : index === 1 ? 'CO6' : index === 2 ? 'EWR31' : 'BOS32'} Deployment {index === 0 ? '64' : index === 1 ? '11' : index === 2 ? '16': '35'}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {index === 0 ? 'Quincy, WA' : index === 1 ? 'Quincy, WA' : index === 2 ? 'Newark, NJ' : 'Boston, MA'}
                  </Typography>
                </Box>
              </Box>
            </ClickableBox>
          );
        })}
      </CardContent>
    </StyledCard>
  ))}
</SliderContainer>
      {showRightArrow && (
        <ScrollButton direction="right" onClick={() => handleScroll('right')} sx={{right: '8px'}}>
          <ArrowForwardIosIcon fontSize="small" sx={{color: '#BFECFF', backgroundColor: '#333'}}/>
        </ScrollButton>
      )}
    </Box>
  );
};

export default DeploymentsWindow;










