import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const DeliveriesInfo = ({ deliveries, delayedClickHandler, reRouted = false }) => {
    
    const getStatusControl = (status) => {
        switch (status) {
            case 'Delayed':
                if (reRouted) {
                    return  <Button variant="contained" color="primary" disabled>Re-Routed</Button>
                } 
                return <Button startIcon={<WarningIcon />} variant='contained' color='error' onClick={delayedClickHandler}>{status}</Button>; 
            default:
                return <span style={{ backgroundColor: getStatusColor(status), color: '#fff', padding: '5px 10px', borderRadius: '4px' }}>
                    {status}
                </span>; 
        }
    };
    
    return (
        <Box sx={{ height: '100%', overflow: 'auto' }}>
            {deliveries.map((delivery) => (
            <Paper key={delivery.id} sx={{ mb: 2, backgroundColor: '#333333' }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery ID</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery Order</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery Type</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery Datetime</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery Weight</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Delivery Dimensions</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Install Time</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ color: '#fff' }}>{delivery.id}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.orderSequence}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.summaryType}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.deliveryDatetime}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.deliveryWeight}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.deliveryDimensions}</TableCell>
                                <TableCell style={{ color: '#fff' }}>{delivery.installTime}</TableCell>
                                <TableCell>{getStatusControl(delivery.status)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Typography variant="h6" sx={{ color: 'white', mt: 2, mb: 1, px: 2 }}>
                        Packing List
                    </Typography>
                    <TableContainer>
                        <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ color: '#fff' }}>SKU</TableCell>
                                <TableCell style={{ color: '#fff' }}>Item</TableCell>
                                <TableCell style={{ color: '#fff' }}>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {delivery.shipments.map((shipment) =>
                                shipment.packingList.map((item) => (
                                    <TableRow key={item.sku}>
                                        <TableCell style={{ color: '#fff' }}>{item.sku}</TableCell>
                                        <TableCell style={{ color: '#fff' }}>{item.item}</TableCell>
                                        <TableCell style={{ color: '#fff' }}>{item.quantity}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            ))}
        </Box>
    );
};

const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
            return '#FFA500'; 
        case 'Completed':
            return '#008000'; 
        case 'OnTime':
            return '#008000'; 
        case 'Delayed':
            return '#FF0000'; 
        default:
            return '#333'; 
    }
};

export default DeliveriesInfo;
