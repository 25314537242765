import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as atlas from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css';
import Supercluster from 'supercluster';

const AzureMapDirect = ({ map, mapStyle, activeTransits, showDCInfo, zoomTo, zoomScale = 17, datacentersData, seaportsData, airportsData}) => {
    const [lastClickedMarker, setLastClickedMarker] = useState(null);
    const [dcLayer, setDcLayer] = useState(null);
    const [dcSource, setDcSource] = useState(null);
    const [dcClusterLayers, setDcClusterLayers] = useState(null);
    const [spLayer, setSpLayer] = useState(null);
    const [spSource, setSpSource] = useState(null);
    const [spClusterLayers, setSpClusterLayers] = useState(null);
    const [apLayer, setApLayer] = useState(null);
    const [apSource, setApSource] = useState(null);
    const [apClusterLayers, setApClusterLayers] = useState(null);
    const datacenterLayersRef = useRef([]);
    const seaportLayersRef = useRef([]);
    const airportLayersRef = useRef([]);
    const pieChartRefs = useRef(new Map());
    const isInitializedRef = useRef(false);

    const cleanupMarkers = useCallback(() => {
      pieChartRefs.current.forEach((marker, key) => {
        if (map.markers && map.markers.remove) {
          map.markers.remove(marker);
        }
      });
      pieChartRefs.current.clear();
    }, [map.markers]);

    const addMarker = useCallback((clusterId, position, parameters) => {
      const htmlContent = createHtmlMarkerContent(parameters);
      
      const marker = new atlas.HtmlMarker({
        position: position,
        htmlContent: htmlContent,
        pixelOffset: { x: 0, y: -40 }
      });

      if (!pieChartRefs.current.has(clusterId)) {
        pieChartRefs.current.set(clusterId, marker);
      
        map.markers.add(marker);
      }
      
      setTimeout(() => {
        if (pieChartRefs.current.has(clusterId) && map.markers && map.markers.remove) {
          map.markers.remove(marker);
          pieChartRefs.current.delete(clusterId);
        }
      }, 4000);
    }, [map.markers]);

    const generateClusterImage = (properties) => {
      
      const early = properties.early || 0;
      const onTime = properties.onTime || 0;
      const maybeLate = properties.maybeLate || 0;
      const defLate = properties.defLate || 0;
    
      const { defaultSvg } = createSvg({
        values: [early, onTime, maybeLate, defLate],
        colors: ['#33FF57', '#3357FF', '#F3FF33', '#FF5733'],
        radius: 30,
        fillColor: 'white',
        strokeWidth: 1,
        strokeColor: 'black',
        innerRadius: 30 * 0.6,
        text: properties.point_count,
        textClassName: 'pie-chart-text',
        includeBackground: true});
    
      return defaultSvg;
    };

    const createSvg = (parameters) => {
      const { values, colors, radius, innerRadius, strokeWidth, strokeColor, text, textClassName, includeBackground } = parameters;
      const total = values.reduce((sum, value) => sum + value, 0);
      let startAngle = 0;
    
      const createPaths = () => {
        const nonZeroCount = values.filter(value => value > 0).length;
        const nonZeroIndex = values.findIndex(value => value > 0);
    
        if (nonZeroCount === 1) {
          return `<circle cx="${radius}" cy="${radius}" r="${radius}" fill="${colors[nonZeroIndex]}" />`;
        }
    
        return values.map((value, index) => {
          const angle = (value / total) * 360;
          const endAngle = startAngle + angle;
          const largeArcFlag = angle > 180 ? 1 : 0;
          const startRadians = (startAngle * Math.PI) / 180;
          const endRadians = (endAngle * Math.PI) / 180;
    
          const x1 = radius + radius * Math.sin(startRadians);
          const y1 = radius - radius * Math.cos(startRadians);
          const x2 = radius + radius * Math.sin(endRadians);
          const y2 = radius - radius * Math.cos(endRadians);
    
          const d = [
            `M${radius},${radius}`,
            `L${x1},${y1}`,
            `A${radius},${radius} 0 ${largeArcFlag},1 ${x2},${y2}`,
            'Z'
          ].join(' ');
    
          startAngle = endAngle;
          return `<path d="${d}" fill="${colors[index]}" />`;
        }).join('');
      };
    
      const backgroundCircles = includeBackground ? 
        `<circle cx="${radius}" cy="${radius}" r="${radius}" fill="${strokeColor}" />
         <circle cx="${radius}" cy="${radius}" r="${radius - strokeWidth}" fill="white" />` : '';
    
      const svgContent = `
        ${backgroundCircles}
        ${createPaths()}
        <circle cx="${radius}" cy="${radius}" r="${innerRadius}" fill="white" />
        <text x="${radius}" y="${radius}" text-anchor="middle" dominant-baseline="central" class="${textClassName}">
          ${text}
        </text>
      `;
    
      const defaultSvg = `
        <svg width="${radius * 2}" height="${radius * 2}" viewBox="0 0 ${radius * 2} ${radius * 2}" xmlns="http://www.w3.org/2000/svg">
          ${svgContent}
        </svg>
      `;
    
      return { defaultSvg };
    };

    const createHtmlMarkerContent = (parameters) => {
      const { values, colors } = parameters;
      const total = values.reduce((sum, value) => sum + value, 0);
      const labelTexts = ['Early', 'On Time', 'Risk', 'Late'];
      const percentages = values.map(value => ((value / total) * 100).toFixed(0));
    
      return `
        <div style="
          position: relative;
          background-color: rgba(255, 255, 255, 0.9);
          padding: 4px;
          border-radius: 4px;
          font-family: Arial, sans-serif;
          min-width: 80px;
        ">
          ${values.map((value, index) => {
            if (parseFloat(percentages[index]) > 0) {
              return `
                <div style="
                  display: flex;
                  align-items: center;
                  margin: 4px 0;
                ">
                  <div style="
                    width: 25px;
                    height: 13px;
                    background-color: ${colors[index]};
                    margin-right: 4px;
                  "></div>
                  <div style="
                    color: black;
                    font-size: 11px;
                    font-weight: bold;
                  ">
                    ${percentages[index]}% ${labelTexts[index]}
                  </div>
                </div>
              `;
            }
            return '';
          }).join('')}
          <div style="
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid rgba(255, 255, 255, 0.9);
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
          "></div>
        </div>`;
    };

    const initializeDataImages = useCallback(async (map, data) => {
      if (!map || !data) return;
    
      const index = new Supercluster({
        radius: 150,
        maxZoom: 13,
        map: props => ({
          early: props.EntityType === 'early' ? 1 : 0,
          onTime: props.EntityType === 'onTime' ? 1 : 0,
          maybeLate: props.EntityType === 'maybeLate' ? 1 : 0,
          defLate: props.EntityType === 'defLate' ? 1 : 0
        }),
        reduce: (accumulated, props) => {
          accumulated.early += props.early;
          accumulated.onTime += props.onTime;
          accumulated.maybeLate += props.maybeLate;
          accumulated.defLate += props.defLate;
        }
      });
    
      index.load(data.features.map(feature => ({
        type: 'Feature',
        properties: feature.properties,
        geometry: feature.geometry
      })));
    
      const addImageToSprite = (map, imageId, svgString) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            try {
              map.imageSprite.add(imageId, img);
              resolve();
            } catch (error) {
              console.error('Error adding image to sprite:', error);
              reject(error);
            }
          };
          img.onerror = (error) => {
            console.error('Error loading image:', error);
            reject(error);
          };
          img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
        });
      };
    
      const addedImages = new Set();
      
      for (let zoom = 0; zoom <= 13; zoom++) {
        const clusters = index.getClusters([-180, -90, 180, 90], zoom);
        for (const cluster of clusters) {
          if (cluster.properties && cluster.properties.cluster_id) {
            const clusterId = cluster.properties.cluster_id;
            
            const imageId = `cluster-${clusterId}`;
            if (!addedImages.has(imageId)) {
              const svgString = generateClusterImage(cluster.properties);
              await addImageToSprite(map, imageId, svgString);

              addedImages.add(imageId);
            } 
          }
        }
      }
    },[]);

  const initializeDataSource = useCallback(async (map, data, seaportsData, airportsData) => {
        if (!map || !data || !seaportsData || !airportsData) return;

        let dcDataSource = map.sources.getById('dcDataSource');
        
        if (!dcDataSource) {
          dcDataSource = new atlas.source.DataSource('dcDataSource', {
            cluster: true,   
            clusterRadius: 150,
            clusterMaxZoom: 13,
            clusterProperties: {
              'early': ['+', ['case', ['==', ['get', 'EntityType'], 'early'], 1, 0]],
              'onTime': ['+', ['case', ['==', ['get', 'EntityType'], 'onTime'], 1, 0]],
              'maybeLate': ['+', ['case', ['==', ['get', 'EntityType'], 'maybeLate'], 1, 0]],
              'defLate': ['+', ['case', ['==', ['get', 'EntityType'], 'defLate'], 1, 0]]
            }
          });


          const enhancedData = data.features.map(feature => {
            return new atlas.Shape(new atlas.data.Point(feature.geometry.coordinates), null, {
                ...feature.properties,
                facility: JSON.stringify(feature)
            });
        });

        dcDataSource.add(enhancedData);
        map.sources.add(dcDataSource);

        } 

        const dcClusterLayer = new atlas.layer.BubbleLayer(dcDataSource, 'dcClusterLayer', {
          radius: [
              'step',
              ['get', 'point_count'],
              30,
              400,
              30,
              500,
              30
          ],
          color: 'transparent',
          strokeColor: 'transparent',
          strokeWidth: 0,
          filter: ['has', 'point_count']
      });
        
        const dcClusterLayer_1 = new atlas.layer.SymbolLayer(dcDataSource, 'dcClusterLayer_1', {
          iconOptions: { 
            image: ['concat', 'cluster-', ['get', 'cluster_id']],
            anchor: 'bottom', 
            offset: [0, 30], 
            allowOverlap: true,
            ignorePlacement: true },
          filter: ['has', 'point_count']
      });


        const dcClusterTextLayer = new atlas.layer.SymbolLayer(dcDataSource, 'dcClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4]
            },
            filter: ['has', 'point_count']
        });
        
        const dcSymbolLayer = new atlas.layer.SymbolLayer(dcDataSource, 'dcSymbolLayer', {
            iconOptions: { image: 'marker-blue' },
            textOptions: {
                textField: ['get', 'FACILITY'],
                offset: [0, 1.5],
                color: 'black',
                haloColor: 'white',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        
        map.layers.add([dcClusterLayer, dcClusterTextLayer, dcSymbolLayer, dcClusterLayer_1]);
        setDcClusterLayers([dcClusterLayer, dcClusterTextLayer, dcClusterLayer_1]);
        setDcLayer(dcSymbolLayer);
        setDcSource(dcDataSource);

        datacenterLayersRef.current = [dcClusterLayer, dcClusterTextLayer, dcSymbolLayer, dcClusterLayer_1];

        const spDataSource = new atlas.source.DataSource(null, {
            cluster: true,
            clusterRadius: 150,
            clusterMaxZoom: 5,
        });
        
        map.sources.add(spDataSource);
        setSpSource(spDataSource);

        const seaportFeatures = seaportsData.map(port => new atlas.data.Feature(
            new atlas.data.Point([port.lon, port.lat]),
            { name: port.tags.name, type: 'seaport', ...port.tags }
        ));
        
        spDataSource.add(seaportFeatures);
        
        const spClusterLayer = new atlas.layer.BubbleLayer(spDataSource, 'spClusterLayer', {
            radius: [
                'step',
                ['get', 'point_count'],
                20,
                400,
                30,
                500,
                20
            ],
            color: 'yellow',
            strokeColor: 'black',
            strokeWidth: 1,
            filter: ['has', 'point_count']
        });
        
        const spClusterTextLayer = new atlas.layer.SymbolLayer(spDataSource, 'spClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                color: 'black'
            },
            filter: ['has', 'point_count']
        });
        
        const spSymbolLayer = new atlas.layer.SymbolLayer(spDataSource, 'spSymbolLayer', {
            iconOptions: { image: 'marker-yellow', size: 0.5 },
            textOptions: {
                textField: ['get', 'name'],
                offset: [0, 1.5],
                color: 'yellow',
                haloColor: 'black',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        map.layers.add([spClusterLayer, spClusterTextLayer, spSymbolLayer]);
        setSpClusterLayers([spClusterLayer, spClusterTextLayer]);
        setSpLayer(spSymbolLayer);
        
        seaportLayersRef.current = [spClusterLayer, spClusterTextLayer, spSymbolLayer];
        
        const apDataSource = new atlas.source.DataSource(null, {
            cluster: true,
            clusterRadius: 400,
            clusterMaxZoom: 8
        });
        
        map.sources.add(apDataSource);
        setApSource(apDataSource);

        const apFeatures = airportsData.map(airport => new atlas.data.Feature(
            new atlas.data.Point([airport.lon, airport.lat]),
            { name: airport.tags.name, type: 'airport', ...airport.tags }
        ));
        
        apDataSource.add(apFeatures);
        
        const apClusterLayer = new atlas.layer.BubbleLayer(apDataSource, 'apClusterLayer', {
            radius: [
                'step',
                ['get', 'point_count'],
                20,
                200,
                30,
                400,
                20
            ],
            color: 'darkblue',
            strokeColor: 'blue',
            strokeWidth: 1,
            filter: ['has', 'point_count']
        });
        
        const apClusterTextLayer = new atlas.layer.SymbolLayer(apDataSource, 'apClusterTextLayer', {
            iconOptions: { image: 'none' },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                offset: [0, 0.4],
                color: 'white'
            },
            filter: ['has', 'point_count']
        });
        
        const apSymbolLayer = new atlas.layer.SymbolLayer(apDataSource, 'apSymbolLayer', {
            iconOptions: { image: 'marker-red', size: 0.5 },
            textOptions: {
                textField: ['get', 'name'],
                offset: [0, 1.5],
                color: 'orange',
                haloColor: 'black',
                haloWidth: 1,
                placement: 'point',
                allowOverlap: true,
                ignorePlacement: false
            },
            filter: ['!', ['has', 'point_count']]
        });
        map.layers.add([apClusterLayer, apClusterTextLayer, apSymbolLayer]);
        setApClusterLayers([apClusterLayer, apClusterTextLayer]);
        setApLayer(apSymbolLayer);
        
        airportLayersRef.current = [apClusterLayer, apClusterTextLayer, apSymbolLayer];
    }, []);

    useEffect(() => {
      if (!map || !datacentersData || !seaportsData || !airportsData || isInitializedRef.current) return;
      
      const initialize = async () => {
        await initializeDataImages(map, datacentersData);
        await initializeDataSource(map, datacentersData, seaportsData, airportsData);
        isInitializedRef.current = true;
      };
    
      initialize();
    }, [map, datacentersData, seaportsData, airportsData, initializeDataImages, initializeDataSource]);

    useEffect(() => {
        if (map) {
            map.setStyle({ style: mapStyle });
        }
    }, [map, mapStyle]);

    useEffect(() => {
        if (!map || !dcClusterLayers || !dcLayer || !spLayer || !spClusterLayers || !apLayer || !apClusterLayers || !activeTransits) return;
        const setLayerVisibility = (layer, visible) => {
            layer.setOptions({ visible: visible });
        };
        if (activeTransits.datacenters) {
            dcClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(dcLayer, true);
        } else {
            dcClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(dcLayer, false);
        }
        if (activeTransits.seaports) {
            spClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(spLayer, true);
        } else {
            spClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(spLayer, false);
        }
        if (activeTransits.airports) {
            apClusterLayers.forEach(layer => setLayerVisibility(layer, true));
            setLayerVisibility(apLayer, true);
        } else {
            apClusterLayers.forEach(layer => setLayerVisibility(layer, false));
            setLayerVisibility(apLayer, false);
        }
    }, [map, activeTransits, dcClusterLayers, dcLayer, spLayer, spClusterLayers, apLayer, apClusterLayers]);
  
   
    const handleClusterClick = useCallback((e) => {
      if (!dcSource || !map) return;
      
      const properties = e.shapes[0].properties;
      const position = e.shapes[0].geometry.coordinates;
      if (properties.cluster) {
          
        cleanupMarkers();

        dcSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
              map.setCamera({
                  center: position,
                  zoom: zoom + 2,
                  type: 'ease',
                  duration: 400,
                  easingFunction: 'easeOutQuad'
              });
          });
        }
  }, [dcSource, map, cleanupMarkers]);
  
  

  const handleClusterMouseOver = useCallback((e) => {
    if (!dcSource || !map) return;
    
    const properties = e.shapes[0].properties;
    const position = e.shapes[0].geometry.coordinates;
    
    const markerParameters = {
      values: [properties.early, properties.onTime, properties.maybeLate, properties.defLate],
      colors: ['#33FF57', '#3357FF', '#F3FF33', '#FF5733']
    };

    const total =  properties.early + properties.onTime + properties.maybeLate + properties.defLate;
    if (total > 0) {
      addMarker(properties.cluster_id, position, markerParameters);
    }
   
  }, [dcSource, map, addMarker]);

  
  

  

    const handleSeaportClusterClick = useCallback((e) => {
        if (!spSource || !map) return;
        const properties = e.shapes[0].properties;
        const position = e.position;
        if (properties.cluster) {
            map.getCanvasContainer().style.cursor = 'grab';
            spSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
                map.setCamera({
                    center: position,
                    zoom: zoom + 2,
                    type: 'ease',
                    duration: 100,
                    easingFunction: 'easeOutCubic'
                });
            });
        }
    }, [spSource, map]);

    const handleApClusterClick = useCallback((e) => {
        if (!apSource || !map) return;
        const properties = e.shapes[0].properties;
        const position = e.position;
        if (properties.cluster) {
            map.getCanvasContainer().style.cursor = 'grab';
            apSource.getClusterExpansionZoom(properties.cluster_id).then((zoom) => {
                map.setCamera({
                    center: position,
                    zoom: zoom + 2,
                    type: 'ease',
                    duration: 100,
                    easingFunction: 'easeOutCubic'
                });
            });
        }
    }, [apSource, map]);

    

    const handlePinClick = useCallback((e) => {
        if (!map) return;
        const properties = e.shapes[0].getProperties();
        const position = e.shapes[0].getCoordinates();
        if (lastClickedMarker) {
            map.markers.remove(lastClickedMarker);
        }
        const color = properties.EntityType === 'early' ? 'green' : 'red';
        const newMarker = new atlas.HtmlMarker({ position: position, color: color });
        map.markers.add(newMarker);
        setLastClickedMarker(newMarker);
        const facility = properties.facility ? JSON.parse(properties.facility) : properties;
        showDCInfo(facility);
    }, [map, lastClickedMarker, showDCInfo]);

    useEffect(() => {
      if (map && dcLayer && dcClusterLayers && spLayer && spClusterLayers && apLayer && apClusterLayers) {
          map.events.add('click', dcLayer, handlePinClick);
         
          dcClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleClusterClick);
                  map.events.add('mouseover', layer, handleClusterMouseOver);
              }
          });
          
          spClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleSeaportClusterClick);
              }
          });
          apClusterLayers.forEach(layer => {
              if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                  map.events.add('click', layer, handleApClusterClick);
              }
          });

          return () => {
              map.events.remove('click', dcLayer, handlePinClick);
              dcClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleClusterClick);
                      map.events.remove('mouseover', layer, handleClusterMouseOver);
                  }
              });
              spClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleSeaportClusterClick);
                  }
              });
              apClusterLayers.forEach(layer => {
                  if (layer.getOptions().filter && layer.getOptions().filter[0] === 'has' && layer.getOptions().filter[1] === 'point_count') {
                      map.events.remove('click', layer, handleApClusterClick);
                  }
              });
          };
      }
  }, [map, handlePinClick, handleClusterClick, handleClusterMouseOver, handleSeaportClusterClick, handleApClusterClick, dcLayer, dcClusterLayers, spLayer, spClusterLayers, apLayer, apClusterLayers]);
  
  

  useEffect(() => {
      if (!zoomTo) return;
  
      if (map && zoomTo) {
          map.setCamera({
              center: zoomTo,
              zoom: zoomScale,  
              type: 'ease',
              duration: 100,
              easingFunction: 'easeOutCubic' 
          });
      }
  }, [map, zoomTo, zoomScale]);

  if (!datacentersData) {
      return <div>Loading...</div>;
  }
  
  return null;
  
  };
  
  export default AzureMapDirect;