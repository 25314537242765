import React from 'react';
import { Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CloseIcon from '@mui/icons-material/Close';
import OSMLanduseChart from './OSMLanduseChart';

const data = {
  "Forests": { "color": "#00FF00", "percentage": 38.13 },
  "Arable": { "color": "#FFFF00", "percentage": 27.64 },
  "Urban": { "color": "#FF0000", "percentage": 11.87 },
  "Commercial": { "color": "rgba(177, 7, 233, 1.0)", "percentage": 4.2},
  "Artificial": { "color": "rgba(231, 175, 249, 1.0)", "percentage": 3.8},
  "Water": { "color": "rgba(134, 198, 237, 1.0)", "percentage": 2.48}
}

const LanduseTimeline = ({ onClose, onYearSelect, selectedYear, landuseData = data, onOverlayChange }) => {
  const years = [2020, 2021, 2022, 2023, 2024];

  const handleYearSelect = (year) => {
    onYearSelect(year);
    if (year === 2024) {
      onOverlayChange({ gapFilled2020: false });
    } else if (year === 2020) {
      onOverlayChange({ gapFilled2020: true });
    }
  };

  return (
    <Box sx={{ height: '300px', backgroundColor: '#333333', color: 'white', p: 2, overflow: 'hidden', position: 'relative' }}>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
          },
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2, position: 'absolute', top: 20, left: 16 }}>Land Impact</Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', height: 'calc(100% - 55px)', mt: 5 }}>
        <Box sx={{ width: '30%' }}>
          <Timeline
            sx={{
              [`& .MuiTimelineItem-root:before`]: {
                flex: 0,
                padding: 0,
              },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              p: 0,
              width: '100%', 
              margin: '0 auto',
            }}
          >
            {years.map((year, index) => (
              <TimelineItem key={year} sx={{ minWidth: 'auto', flex: 1 }}>
                <TimelineSeparator>
                  <TimelineDot 
                    color={year === selectedYear ? "secondary" : "primary"} 
                    onClick={() => handleYearSelect(year)}
                    sx={{ 
                      cursor: year === 2020 || year === 2024 ? 'pointer' : 'not-allowed',
                      opacity: year === 2020 || year === 2024 ? 1 : 0.5
                    }}
                  />
                  {index < years.length - 1 && (
                    <TimelineConnector sx={{ 
                      width: '100%', 
                      height: 2, 
                      backgroundColor: year === 2020 || year === 2024 ? 'primary.main' : 'rgba(255, 255, 255, 0.3)'
                    }} />
                  )}
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 1 }}>
                  <Typography variant="body2" sx={{ opacity: year === 2020 || year === 2024 ? 1 : 0.5 }}>{year}</Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
        
        <Box sx={{
          width: '300px',
          height: '300px',
        }}>
          <OSMLanduseChart data={data} />
        </Box>
        
        <Box sx={{ width: '30%' }}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {landuseData && Object.entries(landuseData).map(([key, value]) => (
                    <TableCell key={key} sx={{ borderBottom: 'none', padding: '4px', color: 'white', textAlign: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ width: 12, height: 12, backgroundColor: value.color, marginBottom: 1 }} />
                        <Typography variant="body2" sx={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>{key}</Typography>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {landuseData && Object.entries(landuseData).map(([key, value]) => (
                    <TableCell key={key} sx={{ borderBottom: 'none', padding: '4px', color: 'white', textAlign: 'center' }}>
                      <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>{value.percentage.toFixed(2)}%</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default LanduseTimeline;