import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/authentication/AuthContext';
import { UserProvider } from './components/UserContext';
import PrivateRoute from './components/authentication/PrivateRoute';
import Login from './components/authentication/Login';
import { LayoutMain } from './components/LayoutMain';
import './firebase';

function App() {
  

  return (
      <div style={{backgroundColor: '#1A252F', minHeight: '100vh'}}>
        <AuthProvider>
          <UserProvider>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route 
                  path="/*" 
                  element={
                    <PrivateRoute>
                      <LayoutMain />
                    </PrivateRoute>
                  } 
                />
              </Routes>
            </Router>
          </UserProvider>
        </AuthProvider>
      </div>
  );
}

export default App;