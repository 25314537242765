import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

const filterOptions = {
  "region-filter": {
    "filter-name": "Region(s)",
    "filter-value": "DCOPSREGION",
    "filter-type": "Normal",
    "options": [
      { "value": "APAC", "label": "APAC" },
      { "value": "Canada", "label": "Canada" },
      { "value": "EMEA", "label": "EMEA" },
      { "value": "LATAM", "label": "LATAM" },
      { "value": "US East", "label": "US East" },
      { "value": "US West", "label": "US West" }
    ]
  },
  "entity-filter": {
    "filter-name": "Deployment Timing(s)",
    "filter-value": "EntityType",
    "filter-type": "Normal",
    "options": [
      { "value": "early", "label": "Projected Early" },
      { "value": "onTime", "label": "Projected On-Time" },
      { "value": "maybeLate", "label": "Projected Late" },
      { "value": "defLate", "label": "Delayed" },
      { "value": "noDeployment", "label": "No Deployment" }
    ]
  },
  "owner-filter": {
    "filter-name": "Facility Owner",
    "filter-value": "FACILITYOWNER",
    "filter-type": "Normal",
    "options": [
      { "value": "Microsoft", "label": "Microsoft" },
      { "value": "-Microsoft", "label": "Other" }
    ]
  }
};

const FacilityFilter = ({ onFilterChange, onCancel, onClear, mode, currentFilters }) => {
  const handleFilterChange = (filterKey, value) => {
    const newFilters = {
      ...currentFilters,
      [filterKey]: value
    };
    onFilterChange(newFilters);
  };

  if (mode !== ('datacenters' || 'default')) {
    return null; 
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      {Object.entries(filterOptions).map(([key, filter]) => (
        <FormControl key={key} fullWidth>
          <InputLabel sx={{ color: '#BFECFF' }}>{filter['filter-name']}</InputLabel>
          <Select
            value={currentFilters[key] || ''}
            onChange={(e) => handleFilterChange(key, e.target.value)}
            label={filter['filter-name']}
            sx={{
              color: '#BFECFF',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#BFECFF',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BFECFF',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#BFECFF',
              },
              '.MuiSvgIcon-root': {
                color: '#BFECFF',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: '#1D1B20',
                  color: '#BFECFF',
                  '& .MuiMenuItem-root': {
                    '&:hover': {
                      backgroundColor: '#2F2D32',
                    },
                    '&.Mui-selected': {
                      backgroundColor: '#2F2D32',
                    },
                  },
                },
              },
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            {filter.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="contained"
          onClick={onClear}
          sx={{
            backgroundColor: '#444B4D',
            color: '#BFECFF',
            '&:hover': {
              backgroundColor: '#2F2D32',
            },
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            backgroundColor: '#444B4D',
            color: '#BFECFF',
            '&:hover': {
              backgroundColor: '#2F2D32',
            },
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default FacilityFilter;