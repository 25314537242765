import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TextField, Button, Box, Typography, Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import exadelLogo from '../../images/exadel-logo.png';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#aaa',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6c63ff',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  },
  '& .MuiInputBase-input': {
    color: '#000',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007A7C',
  color: 'white',
  '&:hover': {
    backgroundColor: '#006668',
  },
}));

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      console.error('Error logging in:', error);
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000',
        backgroundImage: 'linear-gradient(147deg, #000000 0%, #2c3e50 74%)',
      }}
    >
      <Box
        sx={{
          width: '400px',
          padding: '40px',
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ mb: 3, textAlign: 'center' }}>
          <img src={exadelLogo} alt="Exadel Logo" style={{ height: '40px' }} />
        </Box>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Sign in
        </Typography>
        <Typography variant="body2" sx={{ mb: 4, color: '#666' }}>
          to continue to Exadel Serious Gaming
        </Typography>
        <form onSubmit={handleLogin}>
          <StyledTextField
            fullWidth
            label="Email or username"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          <StyledTextField
            fullWidth
            label="Password"
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
          />
          <FormControlLabel
            control={<Checkbox />}
            label={
              <Typography variant="body2" sx={{ color: '#666' }}>
                Keep me signed in
              </Typography>
            }
          />
          <StyledButton
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            disabled={isLoading}
          >
            {isLoading && <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} />}
            Sign in
          </StyledButton>
        </form>
      </Box>
    </Box>
  );
};

export default Login;