import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useMap } from 'react-leaflet';
import { Control, DomUtil } from 'leaflet';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

const OSMMapControls = ({ onOverlayChange, onBasemapChange, overlays, basemap }) => {
    const map = useMap();
    const controlRef = useRef(null);
    const rootRef = useRef(null);
  
    useEffect(() => {
      controlRef.current = new Control({ position: 'topright' });
      controlRef.current.onAdd = () => {
        const div = DomUtil.create('div', 'leaflet-control leaflet-bar');
        rootRef.current = createRoot(div);
        rootRef.current.render(<ControlContent />);
        return div;
      };
      controlRef.current.addTo(map);
  
      return () => {
        if (controlRef.current) {
          controlRef.current.remove();
        }
        if (rootRef.current) {
          rootRef.current.unmount();
        }
      };
    }, [map]);
  
    useEffect(() => {
      if (controlRef.current && rootRef.current) {
        rootRef.current.render(<ControlContent />);
      }
    }, [overlays, basemap]);

  const handleOverlayChange = (event) => {
    onOverlayChange({ ...overlays, [event.target.name]: event.target.checked });
  };

  const handleBasemapChange = (event) => {
    onBasemapChange(event.target.value);
  };

  const ControlContent = () => (
    <Box sx={{ 
      bgcolor: 'rgba(53,53,54,0.9)', 
      p: 2, 
      borderRadius: 1,
      color: 'white'
    }}>
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 'bold', 
          fontSize: '1.2rem', 
          textAlign: 'left', 
          mb: 0.5 
        }}
      >
        Overlays
      </Typography>
      <FormGroup sx={{ '& .MuiFormControlLabel-root': { marginBottom: '-4px' } }}>
        <FormControlLabel
          control={<Checkbox checked={overlays.current} onChange={handleOverlayChange} name="current" sx={{ color: 'white', '&.Mui-checked': { color: '#BFECFF' } }} />}
          label="OSM Landuse (current)"
          sx={{ py: 0 }}
        />
        <FormControlLabel
          control={<Checkbox checked={overlays.gapFilled2020} onChange={handleOverlayChange} name="gapFilled2020" sx={{ color: 'white', '&.Mui-checked': { color: '#BFECFF' } }} />}
          label="Gap-filled OSM Landuse (2020)"
          sx={{ py: 0 }}
        />
      </FormGroup>
      <Typography 
        variant="h6" 
        sx={{ 
          fontWeight: 'bold', 
          fontSize: '1.2rem', 
          textAlign: 'left', 
          mt: 1, 
          mb: 0.5 
        }}
      >
        Basemaps
      </Typography>
      <RadioGroup 
        value={basemap} 
        onChange={handleBasemapChange}
        sx={{ '& .MuiFormControlLabel-root': { marginBottom: '-4px' } }}
      >
        <FormControlLabel value="osmCarto" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: '#BFECFF' } }} />} label="OSM Carto" sx={{ py: 0 }} />
        <FormControlLabel value="esriWorldImagery" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: '#BFECFF' } }} />} label="Esri World Imagery" sx={{ py: 0 }} />
        <FormControlLabel value="off" control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: '#BFECFF' } }} />} label="Off" sx={{ py: 0 }} />
      </RadioGroup>
    </Box>
  );

  return null;
};

export default OSMMapControls;