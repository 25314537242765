import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

const OSMMapUpdater = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      map.setView(center, zoom);
    }
  }, [map, center, zoom]);

  return null;
};

export default OSMMapUpdater;