import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const SCDialog = ({ modalOpen, title, content, actions }) => {
    return (
        <Dialog
            PaperProps={{
                style: {
                    padding: '30px'
                },
            }}
            open={modalOpen}
            disableEscapeKeyDown
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle align='center'>
                {title}
            </DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                {actions}
            </DialogActions>
        </Dialog>
    );
}

export default SCDialog;