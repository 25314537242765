import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Link,
} from "@mui/material";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import datacentersData from "../data/datacenters.json";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';

const filterOptions = {
  "region-filter": {
    "filter-name": "Region(s)",
    "filter-value": "DCOPSREGION",
    "filter-type": "Normal",
    "options": [
      { "value": "APAC", "label": "APAC" },
      { "value": "Canada", "label": "Canada" },
      { "value": "EMEA", "label": "EMEA" },
      { "value": "LATAM", "label": "LATAM" },
      { "value": "US East", "label": "US East" },
      { "value": "US West", "label": "US West" }
    ]
  },
  "entity-filter": {
    "filter-name": "Deployment Timing(s)",
    "filter-value": "EntityType",
    "filter-type": "Normal",
    "options": [
      { "value": "early", "label": "Projected Early" },
      { "value": "onTime", "label": "Projected On-Time" },
      { "value": "maybeLate", "label": "Projected Late" },
      { "value": "defLate", "label": "Delayed" },
      { "value": "noDeployment", "label": "No Deployment" }
    ]
  },
  "owner-filter": {
    "filter-name": "Facility Owner",
    "filter-value": "FACILITYOWNER",
    "filter-type": "Normal",
    "options": [
      { "value": "Microsoft", "label": "Microsoft" },
      { "value": "-Microsoft", "label": "Other" }
    ]
  }
};
const capitalizeWords = (str) => {
  return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};

const FacilityResult = ({
  onFacilitySelect,
  selectedFacility,
  mode,
  filters = {},
  onClearFilters
}) => {
  const [facilities, setFacilities] = useState([]);
  const listRef = useRef(null);
  const [filteredFacilities, setFilteredFacilities] = useState([]);

  const getFilteredFacilities = useMemo(() => {
    const filterAndSort = (mode) => {
      let filtered;
      switch (mode) {
        case 'hubs':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Hub');
          break;
        case 'suppliers':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Supplier');
          break;
        case 'datacenters':
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Datacenter');
          break;
        default:
          filtered = datacentersData.features.filter(f => f.properties.FACILITYTYPE === 'Datacenter');
      }

      return filtered.sort((a, b) =>
        a.properties.FACILITY.localeCompare(b.properties.FACILITY)
      );
    };

    const memoizedResults = {
      datacenters: null,
      hubs: null,
      suppliers: null
    };

    return (mode) => {
      if (!memoizedResults[mode]) {
        memoizedResults[mode] = filterAndSort(mode);
      }
      return memoizedResults[mode];
    };
  }, []);

  useEffect(() => {
    setFacilities(getFilteredFacilities(mode));
  }, [mode, getFilteredFacilities]);

  useEffect(() => {
    if (!filters || typeof filters !== 'object') {
      setFilteredFacilities(facilities);
      return;
    }

    const filtered = facilities.filter((facility) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true;
        const propertyValue = facility.properties[filterOptions[key]['filter-value']];
        if (key === 'owner-filter' && value === '-Microsoft') {
          return propertyValue !== 'Microsoft';
        }
        return propertyValue === value;
      });
    });

    setFilteredFacilities(filtered);
  }, [facilities, filters]);

  const handleFacilityClick = (facility) => {
    onFacilitySelect(facility);
  };

  const groupedFacilities = useMemo(() => {
    const grouped = {};
    filteredFacilities.forEach((facility) => {
      const groupName = facility.properties.CITY || 'Unknown City';
      if (!grouped[groupName]) {
        grouped[groupName] = [];
      }
      grouped[groupName].push(facility);
    });

    const sortedGrouped = Object.keys(grouped)
      .sort()
      .reduce((acc, key) => {
        acc[key] = grouped[key];
        return acc;
      }, {});

    return sortedGrouped;
  }, [filteredFacilities]);

  const areFiltersApplied = Object.values(filters).some(value => value !== '');

  const FacilityLabel = ({ l1, l2, l3 }) => (
    <Box>
      <Typography color="white">{capitalizeWords(l1)}</Typography>
      <Typography color="textDisabled" fontSize="0.75rem">
        {capitalizeWords(l2 || "Unknown City")}, {capitalizeWords(l3 || "Unknown Country")}
      </Typography>
    </Box>
  );


  return (
    <Box
      sx={{
        backgroundColor: '#1D1B20',
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        p: 2,
        overflowY: "auto",
      }}
      ref={listRef}
    >
      <Typography variant="h4" sx={{ fontSize: "30px", mb: 2, color: "white" }}>
        {mode === 'hubs' ? 'Hubs' : mode === 'suppliers' ? 'Suppliers' : 'Data Centers'}
      </Typography>
      {areFiltersApplied && (
        <Link
          component="button"
          variant="body2"
          onClick={onClearFilters}
          sx={{ color: '#BFECFF', mb: 2, textDecoration: 'underline' }}
        >
          Clear filters
        </Link>
      )}

      <SimpleTreeView
        slots={{ expandIcon: ArrowRight, collapseIcon: ArrowDropDown }}
        selectedItems={[selectedFacility?.FacilityName?.toLowerCase() ?? '']}
      >
        {Object.entries(groupedFacilities).map(([groupName, facilities]) => (
          <TreeItem2 sx={{ color: 'white' }} key={groupName} itemId={groupName} label={`${capitalizeWords(groupName)} (${facilities.length})`}>
            {facilities.map((facility, _) => {
              const l1 = facility.properties.FACILITY;
              const l2 = facility.properties.CITY;
              const l3 = facility.properties.COUNTRY;
              return <TreeItem2
                onClick={() => handleFacilityClick(facility)}
                key={facility.properties.FACILITY.toLowerCase()}
                itemId={facility.properties.FACILITY.toLowerCase()}
                label={facility.properties.FACILITY}
                slots={{label:FacilityLabel}}
                slotProps={{label: {l1,l2,l3} }}
              />
            })}
          </TreeItem2>
        ))}
      </SimpleTreeView>
    </Box>
  );
};

export default FacilityResult;